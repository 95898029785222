import _ from "lodash";
import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { fixedShuffleIndex } from "../../../utils/utils";
import getDisplayableOptions from "../../../utils/questionDisplayableOptions";

import Selector from "./selector";
import Slider from "./slider";

const inputTypes = {
  'RADIO': { configByOption: false, optionValuesConfig: ['custom'] },
  'SLIDER': { configByOption: true, optionValuesConfig: ['bounds'] }
}

function defaultInputConfig() {
  return {
    isCustom: false,
    bounds: {
      lower: { value: 1, label: "" },
      upper: { value: 5, label: "" },
      interval: 1
    },
    custom: [
      { value: "", label: "1" },
      { value: "", label: "2" },
      { value: "", label: "3" },
      { value: "", label: "4" },
      { value: "", label: "5" },
    ]
  }
}

function getOptionsMatrix(question) {
  const { optionsMatrix, options} = question;
  const { inputs } = optionsMatrix;

  const normalizedInputs = options.map((_opt, index) => {
    return inputs[index] || defaultInputConfig();
  });

  return { ...optionsMatrix, inputs: normalizedInputs };
}

function getColumnsConfig(question) {
  const { options } = question;
  const { inputs } = getOptionsMatrix(question);
  const { configByOption, optionValuesConfig } = inputTypes[question.optionsMatrix.inputType];

  return options.map((opt, index) => {
    const input = configByOption ? inputs[index] : inputs[0];

    // When custom config allowed and custom values enabled -> return custom values
    if(optionValuesConfig.indexOf('custom') !== -1 && input.isCustom) { 
      return { 
        values: input.custom.map(val => val.label), 
        labels: input.custom.map(val => val.label)
      }
    }

    // When bounds not allowed  -> return default values
    if(optionValuesConfig.indexOf('bounds') === -1) {
      return { 
        values: ['1', '2', '3', '4', '5'], 
        labels: ['1', '2', '3', '4', '5']
      }
    }

    // Generated values from bounds
    const { lower, upper, interval } = input.bounds;
    const cols = [];
    const labels = [];

    for(let i = lower.value; i <= upper.value; i += interval) cols.push(`${i}`);
    for(let i = lower.value; i <= upper.value; i += interval) labels.push('');

    labels[0] = lower.label && lower.label.length > 0 ? lower.label : cols[0];
    labels[labels.length - 1] = upper.label && upper.label.length > 0 ? upper.label : cols[cols.length - 1];

    return { values: cols, labels: labels };
  });
}

function getRecodingsConfig(question) {
  const { options } = question;
  const { inputs } = getOptionsMatrix(question);
  const { configByOption, optionValuesConfig } = inputTypes[question.optionsMatrix.inputType];

  return options.map((opt, index) => {
    const input = configByOption ? inputs[index] : inputs[0];

    // When custom config allowed and custom values enabled -> return custom values
    if(optionValuesConfig.indexOf('custom') !== -1 && input.isCustom) { 
      return input.custom.map(val => val.value) 
    }

    // When bounds not allowed  -> return default values
    if(optionValuesConfig.indexOf('bounds') === -1) {
      return new Array(5).fill('')
    }

    // Generated values from bounds
    const { lower, upper, interval } = input.bounds;
    const recodings = [];
    for(let i = lower.value; i <= upper.value; i += interval) { recodings.push('') }

    return recodings;
  });
}

function getRowsConfig(question) {
  const options = question.options.map((option, index) => ({
    index: index,
    name: option
  }));

  if(!question.randomize) return options;

  return fixedShuffleIndex(options, question.fixed);
}

function initialResponses(length, data) {
  const responses = new Array(length).fill(false);
  for (let i = 0; i < length; i++) { 
    if(data[i]) responses[i] = data[i];
  }
  return responses;
}

export default class Likert extends Component {
  state = { responses: [] };

  constructor(props) {
    super(props);

    const { data, question } = props;
    const { labels } = getOptionsMatrix(question);

    const optionsDisplay = getDisplayableOptions(
      this.props.questionsState,
      this.props.survey.questions,
      question,
      this.props.preview
    );

    let optsToDisplay = {};
    const options =
      question.type === 'LIKERT'
        ? question.optionProps.map((val) => val.text)
        : question.options;
    options.forEach((option, idx) => (optsToDisplay[idx] = option));
    // instead of picking what to display which messed up recorded indices
    // just mark it as null, and let selector.jsx decide to render or not
    optionsDisplay.forEach((el, i) => {
      if (el === false) optsToDisplay[i] = null;
    });
    question.options = _.values(optsToDisplay);

    this.config = {
      rows: getRowsConfig(question),
      columns: getColumnsConfig(question),
      recodings: getRecodingsConfig(question),
      labels: labels,
    };

    this.state = {
      responses: initialResponses(question.options.length, data || []),
    };
  }

  updateResponses(responses) {
    this.setState({ responses: responses }, () => {
      const recodes = responses.map((val, rowIdx) => {
        const colIdx = this.config.columns[rowIdx].values.indexOf(val);
        return this.config.recodings[rowIdx][colIdx] || '';
      });

      // fix ADX-1043 - when moving backwards in survey and de-selecting option...
      // Likert data not reverting to `FALSE`
      // Recode data no reverting to ""
      const { type } = this.props.question;
      if (type === 'LIKERT') {
        const question = this.props.question;

        question.options.forEach((opt, i) => {
          if (opt === null) {
            responses[i] = false;
            recodes[i] = '';
          }
        });
      }

      this.props.onSubmit(responses, { recode: recodes });
    });
  }

  render() {
    // check options array instead of questions array as options represents questions to display
    // prevents error when there is nothing to display
    // TODO: right now question with no options is displayed. should the question be skipped entirely?
    if (!this.props.question?.options.length) return null;

    const { inputType } = this.props.question.optionsMatrix;

    const inputProperties = {
      ...this.config,
      ...this.state,
      updateResponses: (responses) => {
        this.updateResponses(responses);
      },
    };

    return (
      <Paper className="likert-paper" xs={1}>
        {inputType === 'SLIDER' ? (
          <Slider {...inputProperties} />
        ) : (
          <Selector {...inputProperties} />
        )}
      </Paper>
    );
  }
}
