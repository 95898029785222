import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { strpLocalize } from "../localization/localize";
import { addQueryParams, postMessageToParent } from "../utils/utils";
import { postSessionData, postDemographics } from "../redux/actions";
import { trackPage } from "../analytics";
import { dispatchEventScreenedOut } from "../events";

const mapStateToProps = (state) => {
  return { 
    survey: state.survey, 
    sessionId: state.sessionId,
    conditions: state.conditions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postDemographics: (surveyId, sessionId, successCb, confirmContinueCb) => {
      dispatch(postDemographics(surveyId, sessionId, successCb, confirmContinueCb))
    },
    postSessionData: (surveyId, sessionId, data, cb) =>
      dispatch(postSessionData(surveyId, sessionId, data, cb)),
  };
};

function getURL(props) {
  var redirectUrl = props.survey.screenOutLink;
  if (
    props.match.params.surveyId === "603831cadbcea0ab8733af2e" &&
    props.location.search &&
    props.location.search.includes("panel=Cint")
  )
    redirectUrl =
      "https://s.cint.com/Survey/EarlyScreenOut?ProjectToken=56897854-c104-1b13-af90-3e21af79a76a";

  return addQueryParams(
    strpLocalize(redirectUrl),
    {
      phonicSessionId: props.sessionId,
    },
    props.location.search
  );
};

function postSessionDemographics(props) {
  const finishSession = () => {
    const completeAndRedirect = () => {
      const { conditions } = props;
      if (conditions && conditions.truexSessionId) postMessageToParent("interaction::phonic_survey_complete");
      if (props.survey.redirectToScreenOutLink && !props.continuePopupOpen) {
        window.location.href = getURL(props);
      }
    }

    props.postSessionData(
      props.match.params.surveyId,
      props.sessionId,
      { completionStatus: "SCREENED_OUT" },
      completeAndRedirect
    );
  }

  props.postDemographics(
    props.survey._id,
    props.sessionId,
    finishSession,
    finishSession,
  )
}

class ScreenOut extends Component {
  constructor(props) {
    super(props);
    postSessionDemographics(props);
  }
  
  componentDidMount() {
    dispatchEventScreenedOut();
    trackPage("Screen Out", {
      sessionId: this.props.sessionId,
      surveyId: this.props.match.params.surveyId,
    });
  }

  getTitle = () => {
    if (this.props.survey) {
      if (this.props.survey.screenOutTitle)
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: strpLocalize(this.props.survey.screenOutTitle),
            }}
          />
        );

      if (this.props.survey.screenOutLink)
        return strpLocalize("You're Almost Done!");
    }
    return strpLocalize("You're Done!");
  };

  getSubTitle = () => {
    if (this.props.survey && this.props.survey.screenOutLink) {
      return (
        <div>
          {strpLocalize(
            this.props.survey.screenOutSubTitle
              ? this.props.survey.screenOutSubTitle
              : "Follow this link to complete your response"
          )}
          <br />
          <a href={getURL(this.props)}>
            {strpLocalize(this.props.survey.screenOutLink)}
          </a>
        </div>
      );
    }
    if (this.props.survey && this.props.survey.screenOutSubTitle) {
      return <div>{this.props.survey.screenOutSubTitle}</div>;
    }
    return (
      <div>
        {strpLocalize("Thank you for your responses. You can close this tab.")}
      </div>
    );
  };

  render() {
    return (
      <>
        <div>
          <Typography variant="h1" className="title">
            {this.getTitle()}
          </Typography>
          <h2 className="subtitle">{this.getSubTitle()}</h2>
        </div>
        <div style={{ marginTop: 30 }} />
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScreenOut)
);
