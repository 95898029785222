import _ from "lodash";

function evalAnd(result, val, _key) {
  return result && val;
}

function evalOr(result, val, _key) {
  return result || val;
}

// This function exists to evaluate to display or not
// Depending on the type of data/question
function renderIndex(val, likertAndIs) {
  if (typeof val === 'boolean') return val;
  if (likertAndIs) return (likertAndIs.map(o => o.i).includes(val) || likertAndIs.map(o => o.v).includes(val));
  return false;
}

function isSelectionResponseMatching(data, value, likertAndIs) {
  if(!data) return false;

  const indicesToShow = [];

  // Data will be a array of booleans for selection questions
  // It will be an array of strings (likert choices usually 1-5) for likert questions
  data.forEach((valToCheck, idx) => { 
    if (renderIndex(valToCheck, likertAndIs)) {
      indicesToShow.push(`${idx}`)
    } 
  });

  console.log(indicesToShow);

  const matches = _.filter(indicesToShow, val => val === value);
  return matches.length === 0 ? value === null : true;
}

// This logic assumes that the question in the rule is going to be a selection or a dropdown
// Also used in the RadioSelection and CheckboxSelection components
function evaluateQuestionRule(rule, questionsState, surveyQuestions) {
  // for likert questions, value represents the index, and answer represents the user choice to match
  // in the likertAndIs object, i represents the label, and v, the index
  const { questionId, value, likertAndIs, _check } = rule.value;
  const questionIdx = _.findIndex(
    surveyQuestions, 
    q => q.questionId === questionId
  );

  if(!questionId || !value || questionIdx === -1) return true;
  const question = surveyQuestions[questionIdx];
  const qData = questionsState[questionIdx].data;
  const data = qData[question.type];
  
  // Extend this function to support other type of questions
  return isSelectionResponseMatching(data, value, likertAndIs);
}

function evaluateRule(rule, questionsState, surveyQuestions) {
  if(rule.type === 'or' || rule.type === 'and') {
    const initialVal = rule.type === 'or' ? false : true;
    const reducer = rule.type === 'or' ? evalOr : evalAnd;
    const values = rule.value.map(val => evaluateRule(val, questionsState, surveyQuestions));
    let result = _.reduce(values, reducer,  initialVal);
    if(rule.negative === true) result = !result;
    return result;
  } else {
    return evaluateQuestionRule(rule, questionsState, surveyQuestions)
  }
}

function getDisplayableOptions(questionsState, surveyQuestions, question) {
  const indices = Object.keys(question.options);
  if(!question.optionsDisplayConditions) return indices.map((i) => true);
  const { optionsDisplayConditions } = question;
  
  return indices.map(idx => {
    if(!optionsDisplayConditions[idx]) return true;
    
    return evaluateRule(
      optionsDisplayConditions[idx], 
      questionsState, 
      surveyQuestions
    )
  });
}

export default getDisplayableOptions;