import React, { Component } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  getNextButtonContent,
  isLastQuestion,
  showDemographics,
  getSubTitle,
} from "../utils/survey";
import Tour from "../components/Help/Tour";
import SliderType from "./Questions/SliderType";
import MainButton from "../components/Buttons/MainButton";
import RecordButton from "../components/Buttons/RecordButton";
import CheckIcon from "@material-ui/icons/Check";
import { connect } from "react-redux";
import EndMissionPopup from "../components/Mission/EndMissionPopup";
import { ScaleLoader } from "react-spinners";
import HelpText from "../components/Help/HelpText";
import HelpPopup from "../components/Popups/HelpPopup";
import {
  submitFeedback,
  updateQuestionState,
  FRAME_CHECK_URL,
} from "../redux/actions";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingCam from "../components/Recording/FloatingCam";
import AudioAnimation from "../components/Recording/AudioAnimation";
import Ranking from "./Questions/Ranking";
import Likert from "./Questions/Likert/index";
import CheckboxSelection from "./Questions/CheckboxSelection";
import RadioSelection from "./Questions/RadioSelection";
import Dropdown from "./Questions/Dropdown";
import Loading from "./Loading";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    questionState: state.questionState[state.questionIdx],
    questionIdx: state.questionIdx,
    question: state.question,
    feedback: state.feedback,
    preview: state.preview,
    pageQuery: state.conditions.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitFeedback: (feedback, email) =>
      dispatch(submitFeedback(feedback, email)),
    updateQuestionState: (data) => dispatch(updateQuestionState(data)),
  };
};

const tourSteps = [
  {
    content: (
      <>
        <h2>Welcome to a UX Mission</h2>
        <p>You will be performing a sequence of tasks for user testing</p>
      </>
    ),
    placement: "center",
    target: "body",
    styles: {
      tooltipContainer: {
        textAlign: "center",
      },
    },
  },
  {
    target: ".task-description",
    content: (
      <>
        <h3>Task Information</h3>
        <p>The task to be completed is listed here</p>
      </>
    ),
  },
  {
    target: ".ux-iframe",
    content: (
      <>
        <h3>Playground</h3>
        <p>Interact with this area to carry out the task</p>
      </>
    ),
  },
  {
    target: ".next-button",
    content: (
      <>
        <h3>Submit Response</h3>
        <p>After completing the task, Click done to go to the next task</p>
      </>
    ),
  },
  {
    target: ".end-mission",
    content: (
      <>
        <h3>End Mission (if needed)</h3>
        <p>
          You can stop recording and end the mission if the task is unclear or
          confusing. Ending Mission will stop screen share and abandon all
          followup mission tasks. You won't be able to come back to this
          mission.
        </p>
      </>
    ),
  },
  {
    target: "body",
    placement: "center",
    styles: {
      tooltipContainer: {
        textAlign: "center",
      },
    },
    content: (
      <>
        <h2>You are all set! </h2>
        <p>
          Your browser may request access to your screen, camera and microphone
        </p>
      </>
    ),
  },
];

/**
 * Frame Recording component for UX testing
 *
 * @param {bool} webcam - Whether to display a floating webcam video on
 * bottom right of screen recording
 * @param {function} handleComplete - Callback to handle task completion
 * handleComplete
 *
 */

class FrameRecording extends Component {
  state = { textWrap: false, frameLoadState: undefined, iframeLoaded: false };

  expandText = () => this.setState({ textWrap: true });
  condenseText = () => this.setState({ textWrap: false });

  onSubmit = (type, data) => {
    this.props.updateQuestionState({
      data: { ...this.props.questionState.data, [type]: data },
    });
  };

  setFrameLoadState = () => {
    if (this.props.link) {
      this.canLoadInFrame(this.props.link).then((canLoad) => {
        if (canLoad) {
          if (this.state.iframeLoaded)
            this.setState({ frameLoadState: "LOADED" });
        } else this.setState({ frameLoadState: "CANNOT_LOAD" });
      });
    } else this.setState({ frameLoadState: "GET_STARTED" });
  };
  componentDidMount() {
    this.setFrameLoadState();
  }

  isPrototypeLink = () =>
    this.props.link.startsWith("https://www.figma.com/proto") ||
    this.props.link.startsWith("https://www.figma.com/embed");

  canLoadInFrame = async (frameUrl) => {
    if (this.isPrototypeLink()) return true;
    const response = await fetch(`${FRAME_CHECK_URL}?appurl=${frameUrl}`);
    if (response.status === 200) {
      const json = await response.json();
      if (json.data === false) {
        this.setState({ cannotLoadReason: json.reason });
      }
      return json.data;
    }
    return false;
  };

  componentDidUpdate(prevProps) {
    if (this.props.link !== prevProps.link) {
      this.setFrameLoadState();
    }
  }

  getInput = () => {
    if (!this.props.question) return null;

    switch (this.props.question.type) {
      case "SLIDER":
        return (
          <SliderType
            key={`SLIDER-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.onSubmit.bind(this, "SLIDER")}
            data={this.props.questionState.data["SLIDER"]}
            noMargin={true}
          />
        );
      case "DROPDOWN":
        return (
          <Dropdown
            key={`DROPDOWN-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.onSubmit.bind(this, "DROPDOWN")}
            data={this.props.questionState.data["DROPDOWN"]}
          />
        );
      case "SELECTION":
        if (this.props.question.exclusive)
          return (
            <RadioSelection
              key={`RADIO-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.onSubmit.bind(this, "SELECTION")}
              data={this.props.questionState.data["SELECTION"]}
            />
          );
        else
          return (
            <CheckboxSelection
              key={`CHECKBOX-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.onSubmit.bind(this, "SELECTION")}
              data={this.props.questionState.data["SELECTION"]}
            />
          );
      case "LIKERT":
        return (
          <div style={{ textAlign: "center" }}>
            <Likert
              key={`LIKERT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.onSubmit.bind(this, "LIKERT")}
              data={this.props.questionState.data["LIKERT"]}
            />
          </div>
        );
      case "RANKING":
        return (
          <Ranking
            key={`RANKING-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.onSubmit.bind(this, "RANKING")}
            data={this.props.questionState.data["RANKING"]}
          />
        );
      default:
        return <div />;
    }
  };

  builderPreview = () => {
    return (
      this.props.pageQuery !== undefined &&
      (this.props.survey.state === "DRAFT" || this.props.preview)
    );
  };

  allowCameraStream = () => {
    if (this.builderPreview()) return false;
    if (this.props.webcam && this.props.frameState !== "TUTORIAL") return true;
    return false;
  };

  cleanLink = () => {
    let link = this.props.link;
    if (this.props.link.startsWith("https://")) {
      link = this.props.link.slice(8);
    }
    if (link.length >= 16) {
      return `${link.substring(0, 16)}...`;
    }
    return link;
  };

  frameSrc = () => {
    if (this.props.link.startsWith("https://www.figma.com/proto")) {
      return `https://www.figma.com/embed?embed_host=share&url=${this.props.link}&scaling=contain&hide-ui=1`;
    }
    return this.props.link;
  };

  loadFrame = () => {
    switch (this.state.frameLoadState) {
      case "GET_STARTED":
        return (
          <div className={clsx("ux-testing", "ux-iframe", "frame-placeholder")}>
            <h1>{"👋  Create usability tasks for testers"}</h1>
            <h2>Add a link 🔗 of your app or prototype to get started</h2>
            <h3 className="mt-12">
              <u>Link doesn't work?</u>
            </h3>
          </div>
        );
      case "LOADED":
        return <></>;
      case "CANNOT_LOAD":
        return (
          <div className={clsx("ux-testing", "ux-iframe", "frame-placeholder")}>
            <h1>
              {"🚨 Cannot load "}
              {this.cleanLink()}
            </h1>
            <h3 className="mt-4">
              {this.state.cannotLoadReason}. Add the following header to your
              app to resolve the problem:
            </h3>
            <div className="code-border">
              <h3>
                Content-Security-Policy: "frame-ancestors survey.phonic.ai"
              </h3>
            </div>
          </div>
        );
      default:
        return (
          <div className={clsx("ux-testing", "ux-iframe")}>
            <Loading />
          </div>
        );
    }
  };

  render() {
    return (
      <>
        <EndMissionPopup
          open={
            this.props.frameState === "END_WARNING" ||
            this.props.frameState === "END_SUBMIT"
          }
          handleClose={() => {
            this.props.setFrameState("QUESTION");
          }}
          endButtonContent={getNextButtonContent(
            this.props.questionState,
            this.props.survey.buttonType,
            isLastQuestion(this.props.questionIdx, this.props.survey),
            showDemographics(this.props.survey),
            "End Mission"
          )}
          handleEndMission={() => {
            this.props.setFrameState("END_SUBMIT");
          }}
        />
        <div className="ux-testing">
          <iframe
            style={{ background: "#FFFFFF" }}
            title="ux-frame"
            src={this.frameSrc()}
            className={clsx(
              "ux-testing",
              "ux-iframe",
              !this.props.recording &&
                !this.builderPreview() &&
                "disable-interactions"
            )}
            onLoad={() => {
              this.setState({ iframeLoaded: true }, this.setFrameLoadState);
            }}
          />
          {this.loadFrame()}
          {!this.props.recording && !this.builderPreview() && (
            <div
              className={clsx("ux-testing", "ux-iframe", "transparent-loader")}
            >
              {this.props.questionState.requestState === "POST_ATTEMPTED" && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "35vh",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  <ScaleLoader size="30px" color="#62cb88" loading={true} />
                </div>
              )}
            </div>
          )}

          <div
            className={clsx(
              "ux-bar",
              this.props.question.type === "SCREEN" &&
                this.props.frameState !== "HELP" &&
                "ux-bar-min"
            )}
            onMouseEnter={this.expandText}
            onMouseLeave={this.condenseText}
          >
            <div className="mission-progress">
              <LinearProgress
                variant="determinate"
                value={
                  (this.props.missionStep / this.props.totalMissionSteps) * 100
                }
              />
            </div>
            <div className="flex">
              <div className="question-area">
                <div className="task-description">
                  <Typography
                    variant="h6"
                    noWrap={!this.state.textWrap}
                    className="ux-text"
                  >
                    {this.props.question.text
                      ? this.props.question.text
                      : "Write a sentence to summarize the task"}
                  </Typography>
                  <Typography
                    variant="body2"
                    noWrap={!this.state.textWrap}
                    className="ux-text"
                    style={{ marginTop: "0.5em" }}
                  >
                    {getSubTitle(this.props.question, true)}
                  </Typography>
                </div>
                <div className="input-container">
                  {this.props.question.type !== "SCREEN" && this.getInput()}
                </div>
                <HelpText
                  className="ux-text"
                  style={{ marginBottom: 0 }}
                  onClick={() => {
                    this.props.setFrameState("HELP");
                  }}
                />
              </div>
              {/* <div className="mission-progress">
                <h1>{this.props.missionStep}/5</h1>
              </div> */}
              <div className="button-container">
                {(this.props.recording ||
                  this.props.frameState === "TUTORIAL") && (
                  <RecordButton
                    className={clsx("end-mission", "ux-button")}
                    onClick={() => this.props.setFrameState("END_WARNING")}
                    isRecording={this.props.recording}
                  >
                    <FontAwesomeIcon size="lg" icon={faStop} className="stop" />
                  </RecordButton>
                )}
                <MainButton
                  className={clsx("next-button", "ux-button")}
                  onClick={() => {
                    if (this.props.frameState === "QUESTION")
                      this.props.setFrameState("SUBMIT");
                  }}
                  disabled={
                    this.props.questionState.requestState === "POST_ATTEMPTED"
                  }
                  startIcon={
                    this.props.survey.buttonType === "chevron" ||
                    (this.props.questionState.requestState ===
                      "POST_ATTEMPTED" &&
                      this.props.question.type.includes(
                        "SCREEN"
                      )) ? undefined : (
                      <CheckIcon />
                    )
                  }
                >
                  {getNextButtonContent(
                    this.props.questionState,
                    this.props.survey.buttonType,
                    isLastQuestion(this.props.questionIdx, this.props.survey),
                    showDemographics(this.props.survey)
                  )}
                </MainButton>
              </div>
            </div>
          </div>
        </div>
        {this.props.recording && (
          <AudioAnimation
            audioLevel={this.props.audioLevel}
            className="audio-animation"
            animation={{ circle: { minRadius: 38 } }}
          />
        )}
        {this.allowCameraStream() && (
          <FloatingCam hide={!this.props.recording} />
        )}
        <HelpPopup
          open={this.props.frameState === "HELP"}
          handleClose={() => {
            this.props.setFrameState("QUESTION");
          }}
          submitFeedback={this.props.submitFeedback}
          feedback={this.props.feedback}
        />
        {this.props.frameState === "TUTORIAL" && (
          <Tour
            steps={tourSteps}
            onRequestClose={() => {
              if (this.props.recording) this.props.setFrameState("QUESTION");
              else this.props.setFrameState("CAPTURE");
            }}
            closeWithMask={false}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrameRecording);
