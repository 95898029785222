import React, { useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const Tour = ({ steps, onRequestClose }) => {
  const [tourState, setTourState] = useState({
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: true,
    continuous: true, // Show next button
    loading: false,
    stepIndex: 0, // Make the component controlled
    steps: steps,
  });

  const joyRideCallback = ({ action, index, type, status }) => {
    if (
      // If close button clicked then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourState({ ...tourState, run: false, stepIndex: 0 });
      onRequestClose();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step
      setTourState({
        ...tourState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    }
  };

  return (
    <JoyRide
      {...tourState}
      callback={joyRideCallback}
      hideBackButton={true}
      styles={{
        tooltipContainer: {
          textAlign: "left",
        },
        buttonBack: {
          marginRight: 10,
        },
        options: {
          zIndex: 3,
        },
      }}
      locale={{
        last: "Start Mission",
      }}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
    />
  );
};

export default Tour;
