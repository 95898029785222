import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import TextProgress from "../../components/Progress/TextProgress";

class Text extends Component {
  handleChange = (event) => {
    // Enter was pressed without shift key
    this.props.onSubmit(event.target.value);
  };
  render() {
    const showProgress =
      this.props.question &&
      (this.props.question.minTextLength || this.props.question.maxTextLength);

    return (
      <Paper style={{ overflow: "hidden" }}>
        {showProgress && (
          <TextProgress
            length={this.props.data ? this.props.data.length : 0}
            min={this.props.question.minTextLength}
            max={this.props.question.maxTextLength}
          />
        )}
        <TextField
          id="outlined-secondary"
          placeholder="Response"
          variant="outlined"
          color="primary"
          multiline
          fullWidth
          rows="4"
          onChange={this.handleChange.bind(this)}
          disabled={this.props.disabled}
          defaultValue={this.props.data}
          autoFocus={this.props.pageQuery === undefined}
          onKeyDown={(event) => {
            // Enter was pressed without shift key => prevent default behavior
            if (event.key == "Enter" && !event.shiftKey) event.preventDefault();
          }}
          onFocus={this.props.disableKeyboardSelection}
          onBlur={this.props.enableKeyboardSelection}
        />
      </Paper>
    );
  }
}

export default Text;
