import React, { useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { strpLocalize } from "../../localization/localize";
import KeyboardButton from "./KeyboardButton";
import KeyboardKey from "../Keyboard/KeyboardKey";
import KeyboardNavigation from "../Keyboard/KeyboardNavigation";
import { useState } from "react";
import { useEffect } from "react";
import KeyboardShortcutInfo, { COMMAND_KEY, SHIFT_KEY } from "../Keyboard/KeyboardShortcutInfo";

const ShortcutTip = ({ shiftPress, cmdPress, skip, backPress, skipPress }) => {
  return (
    <KeyboardShortcutInfo action={skip?"skip":"go back"}>
      <KeyboardKey pressed={shiftPress}>{SHIFT_KEY}</KeyboardKey>
      <KeyboardKey pressed={cmdPress}>{COMMAND_KEY}</KeyboardKey>
      <KeyboardKey pressed={skip ? skipPress : backPress}>
        {skip ? "S" : "B"}
      </KeyboardKey>
    </KeyboardShortcutInfo>
  );
};

const useStyles = makeStyles({
  root: {
    border: "1px solid",
    borderRadius: 8,
    fontSize: 18,
    marginLeft: (skip) => (skip === true ? 10 : 0),
    marginRight: (skip) => (skip === true ? 0 : 10),
    height: 30,
    minWidth: 30,
    width: 30,
  },
});

const GeneralButton = ({ className, children, skip, ...other }) => {
  const buttonRef = useRef(null);
  const classes = useStyles(skip);
  const [shiftPress, setShiftPress] = useState(false);
  const [cmdPress, setCmdPress] = useState(false);
  const [backPress, setBackPress] = useState(false);
  const [skipPress, setSkipPress] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const tooltipProps = {
    title: (
      <ShortcutTip
        skip={skip}
        shiftPress={shiftPress}
        cmdPress={cmdPress}
        backPress={backPress}
        skipPress={skipPress}
      />
    ),
    placement: skip ? "right" : "left",
    open: isHovering || (shiftPress && cmdPress),
  };

  const resetButtonPress = () => {
    setBackPress(false);
    setSkipPress(false);
  };

  const backOrSkip = () => {
    if (
      shiftPress &&
      cmdPress &&
      buttonRef.current &&
      buttonRef.current.keyboardClick &&
      ((!skip && backPress) || (skip && skipPress))
    )
      buttonRef.current.keyboardClick(null, resetButtonPress);
  };

  useEffect(() => {
    backOrSkip();
  }, [backPress, skipPress]);

  return (
    <>
      <KeyboardButton
        ref={buttonRef}
        className={clsx(classes.root, className, "previous-button")}
        {...other}
        tooltipProps={tooltipProps}
        tooltipAriaLabel={
          skip ? strpLocalize("Skip") : strpLocalize("Previous")
        }
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children || (skip ? <ArrowRightIcon /> : <ArrowLeftIcon />)}
      </KeyboardButton>
      <KeyboardNavigation
        shiftDown={setShiftPress}
        cmdDown={setCmdPress}
        letterDown={(key) => {
          setBackPress(["b", "B"].includes(key));
          setSkipPress(["s", "S"].includes(key));
        }}
      />
    </>
  );
};

export default GeneralButton;
