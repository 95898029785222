// Media related Utils

export const relinquishStreamIfExists = (stream) => {
  if (stream && stream.getTracks())
    stream.getTracks().forEach((track) => track.stop());
};

export const getScreenStream = async () => {
  // Secure the screen stream.
  try {
    if (navigator.getDisplayMedia) {
      return await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      return await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    } else {
      return await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }
  } catch {
    return null;
  }
};

export const getAudioStream = async () => {
  try {
    let audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    return await audioStream;
  } catch {
    return null;
  }
};

export const getVideoStream = async () => {
  try {
    return await navigator.mediaDevices.getUserMedia({
      video: {
        aspectRatio: 1,
      },
      audio: false,
    });
  } catch {
    return null;
  }
};

export const addStreamStopListener = (stream, callback) => {
  const callbackFunc = function () {
    callback();
    callback = function () {};
  };
  stream.addEventListener("ended", callbackFunc, false);
  stream.addEventListener("inactive", callbackFunc, false);
  stream.getTracks().forEach(function (track) {
    track.addEventListener("ended", callbackFunc, false);
    track.addEventListener("inactive", callbackFunc, false);
  });
};

export const getAudioLevel = (media, options, callback) => {
  if (!callback) {
    callback = options;
    options = null;
  }

  options = options || {};

  let that = {};
  const context = options.context || new AudioContext();
  const source = context.createMediaStreamSource(media);
  const analyser = context.createAnalyser();
  let processor = null;

  if (callback) {
    processor = context.createScriptProcessor(2048, 1, 1);
    processor.onaudioprocess = function () {
      callback(that.get());
    };
  }

  analyser.smoothingTimeConstant = 0.3;
  analyser.fftSize = 1024;

  source.connect(analyser);

  that.get = function () {
    var sum = 0;
    var data = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(data);

    for (var i = 0; i < data.length; i++) {
      sum += data[i];
    }

    return sum / data.length / 255;
  };

  that.destroy = function () {
    if (processor) {
      processor.disconnect();
      analyser.disconnect();
    }

    source.disconnect();

    if (!options.context) context.close();
  };

  source.connect(analyser);

  if (processor) {
    analyser.connect(processor);
    processor.connect(context.destination);
  }

  return that;
};
