import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fixedShuffleIndex, reorder } from "../../utils/utils";
import FormControl from "@material-ui/core/FormControl";
import { strpLocalize } from "../../localization/localize";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { cloneDeep } from "lodash";

class Ranking extends Component {
  state = {
    // arr: [],
    display: [],
    options: [],
  };
  componentWillMount() {
    if (this.props.data) {
      this.setState(
        {
          options: this.props.data,
          display: this.props.data
        },
        () => this.props.onSubmit(this.state.options)
      );
    } else if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ display: shuffledOptions })
    } else {
      this.setState({ display: this.props.question.options })
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const optionsClone = cloneDeep(this.state.display);
    const options = reorder(
      optionsClone,
      result.source.index,
      result.destination.index
    );

    this.setState(
      { options: options, display: options }, 
      () => this.props.onSubmit(this.state.options)
    );
  };

  onChange = (option, event) => {
    const oldRank = this.state.display.indexOf(option);
    const selectedRank = event.target.value;

    if (!selectedRank && selectedRank !== 0) {
      return;
    }

    const optionsClone = cloneDeep(this.state.display);
    const options = reorder(optionsClone, oldRank, selectedRank);

    this.setState(
      { options: options, display: options }, 
      () => this.props.onSubmit(this.state.options)
    );
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, droppableSnapshot) => {
            const style = {
              padding: "8px",
              width: "100%",
            };
            return (
              <div className="question-outer-container">
                <div
                  style={style}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.state.display.map((option, idx) => (
                    <Draggable
                      key={`ranking-option-${idx}`}
                      draggableId={`${idx + 1}`}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="ranking-paper-container selection-paper"
                        >
                          <FormControl
                            variant="outlined"
                            className="ranking-dropdown"
                          >
                            <Select
                              labelId="ranking-dropdown-label"
                              id="ranking-dropdown-id"
                              value={this.state.display.indexOf(option)}
                              onChange={(event) => this.onChange(option, event)}
                              className="ranking-dropdown"
                            >
                              {this.state.display.map((option, idx) => (
                                <MenuItem value={idx}>{idx + 1}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div className="option-text">
                            {strpLocalize(option)}
                          </div>
                          <FontAwesomeIcon
                            className="ranking-grab-icon"
                            icon={faGripVertical}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default Ranking;
