import _ from "lodash";
import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import { fixedShuffleIndex } from "../../utils/utils";
import getDisplayableOptions from "../../utils/questionDisplayableOptions";
import { strpLocalize } from "../../localization/localize";
import clsx from "clsx";
import KeyboardNavigation from "../../components/Keyboard/KeyboardNavigation";
import KeyboardKey from "../../components/Keyboard/KeyboardKey";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = (theme) => ({
  "noIconSelectionSvg": {
    "& span": {
      display: "none",
    }
  },
  "noIconSelectionPaper": {
    borderRadius: "16px",
    minHeight: "42px",
    display: "flex",
  },
  "noKeyboardShortcutSelectionPaper": {
    "margin-left": "0px"
  },
  "truexSelectionPaper": {
    borderRadius: "14px",
    minHeight: "28px",
    display: "flex",
    paddingLeft: "20px"
  },
  "truexLabel": {
    fontSize: "14px"
  },
  "truexCheckbox": {
    padding: "2px",
  },
  "truexGridSpacings": {
    paddingLeft: "4px",
    paddingRight: "4px",
  }
});

class CheckboxSelection extends Component {
  state = { arr: [], options: [], shiftPress: false };

  componentWillMount() {
    if (this.props.data) {
      this.setState({
        arr: this.props.data,
      });
    } else {
      this.setState({
        arr: new Array(this.props.question.options.length).fill(false),
      });
    }

    if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ options: shuffledOptions });
    } else {
      this.setState({ options: this.props.question.options });
    }
  }

  getShuffledOptionsIndex = (option) => {
    return this.state.options.indexOf(option);
  };

  getRealOptionIndex = (option) => {
    return this.props.question.options.indexOf(option);
  };

  handleChange = (event) => {
    var optionIdx = this.getRealOptionIndex(event.target.value);
    this.selectOption(optionIdx);
  };

  keySelect = (key) => {
    if (this.getEnableKeyboardShortcut() && key && !this.state.shiftPress) {
      const optionIdx = key.toLowerCase().charCodeAt(0) - 97;
      this.selectOption(optionIdx);
    }
  };

  selectOption = (optionIdx) => {
    if (optionIdx >= this.props.question.options.length) return;
    this.state.arr[optionIdx] = !this.state.arr[optionIdx];
    this.props.onSubmit(this.state.arr);
  };

  getEnableKeyboardShortcut() {
    return !(this.props.disableShortcuts || this.props.disableKeySelection) && !this.props.question.randomize;
  }

  getSelectedClass = (option) => {
    return this.state.arr[this.getRealOptionIndex(option)] ? "selected" : "";
  };

  getTruexFontSize = (classes) => {
    return this.props.isTruex ? classes.truexLabel : "";
  }

  getTruexCheckbox = (classes) => {
    return this.props.isTruex ? classes.truexCheckbox : "";
  }

  getSelectionPaperNoIconClass = (classes) => {
    return this.props.isTruex
        ? classes.truexSelectionPaper
        : (this.props.question && this.props.question.showSelectionIcons === false)
          ? classes.noIconSelectionPaper
          : ""
  };

  getCheckboxNoIconClass = (classes) => {
    return (this.props.question && this.props.question.showSelectionIcons === false) ? classes.noIconSelectionSvg : "";
  };

  getNoKeyboardShortcutClass = (classes) => {
    return (this.props.disableShortcuts || this.props.disableKeySelection) ? classes.noKeyboardShortcutSelectionPaper : "";
  }

  getColumnLayout() {
    return (this.props.isTruex && this.state.options && this.state.options.length > 5) ? 6 : 12;
  }

  getColumnCSS = (classes) => {
    return (this.props.isTruex && this.state.options && this.state.options.length > 5) ? classes.truexGridSpacings : "";
  }

  isMultipleColumns() {
    const displayable = this.displayOptions();
    return this.props.isTruex && displayable && Object.keys(displayable).length > 5
  }

  getMultiColumns() {
    const displayable = this.displayOptions();

    const totalCols = 2;
    const optionKeys = Object.keys(displayable)
    const totalOptions = optionKeys.length;
    const totalOptionsInCol = Math.ceil(totalOptions/totalCols)
    const options = []

    let i = 0;
    for(let col = 0; col < totalCols; col++) {
      const slice = optionKeys.slice(i, i + totalOptionsInCol)
      const colOpts = _.pickBy(displayable, (val, key) => _.includes(slice, key));
      options.push(colOpts);

      i = i + totalOptionsInCol
    }

    return options
  }

  getSingleColumn() {
    return [this.displayOptions()]
  }

  displayOptions() {
    const { question } = this.props;

    let colOpts = {};
    this.state.options.forEach((option, idx) => (colOpts[idx] = option));

    if (!question.optionsDisplayConditions) return colOpts;

    const optionsDisplay = getDisplayableOptions(
      this.props.questionsState,
      this.props.survey.questions,
      question,
      this.props.preview
    );

    const dataToPick = this.props.question.randomize
      ? this.props.question.options
      : colOpts;

    let optsToRet = _.pickBy(dataToPick, (_v, idx) => {
      return optionsDisplay[idx];
    });

    // re-order options to display in the same order as the predetermined random order
    if (this.props.question.randomize) {
      const hasValue = (obj, searchValue) => {
        return _.includes(Object.values(obj), searchValue);
      };

      const filterCommonValues = (obj1, obj2) => {
        return _.pickBy(obj1, (value, key) => hasValue(obj2, value));
      };
      optsToRet = filterCommonValues(colOpts, optsToRet);
    }

    return optsToRet;
  }
    
  
  renderColumn(col) {
    const { classes } = this.props;
    const numOptionsSelected = this.state.arr.filter((x) => x).length;
    const maxNumSelections = this.props.question.maxNumSelections || this.props.question.options.length;
    const newSelectionsDisabled = numOptionsSelected >= maxNumSelections;
    const enableKeyboardShortcuts = this.getEnableKeyboardShortcut();
    const indeces = Object.keys(col);

    return indeces.map(idx => {
      const option = col[idx];
      const currentIdx = this.getRealOptionIndex(option);
      const isSelected = this.state.arr[currentIdx];
      
      return (
        <div key={idx} className="selection-option-container">
          <Grid container direction='row' spacing={0}>
            {
              enableKeyboardShortcuts && <div style={{ margin: 'auto 0', width: '40px' }}>
                <KeyboardKey
                  pressed={this.state.arr[currentIdx]}
                  onClick={() => this.selectOption(currentIdx)}
                >
                  {String.fromCharCode(currentIdx + 65)}
                </KeyboardKey>
              </div>
            }
            <div style={{ 'flex-grow': '1' }}>
              <Paper
                key={`checkbox-selection-${idx}`}
                className={clsx("selection-paper",
                    this.getSelectedClass(option),
                    this.getSelectionPaperNoIconClass(classes),
                    this.getNoKeyboardShortcutClass(classes))
                }
                square
              >
                <FormControlLabel
                  style={{ width: '100%', padding: '2px' }}
                  onChange={(e) => this.handleChange(e)}
                  control={
                    <Checkbox className={clsx("selection", this.getCheckboxNoIconClass(classes), this.getTruexCheckbox(classes))}
                      value={option}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      checked={isSelected}
                      disabled={!isSelected && newSelectionsDisabled}
                    />
                  }
                  label={
                    <Typography variant="body1" className={this.getTruexFontSize(classes)}>
                      <span
                          dangerouslySetInnerHTML={{
                            __html: strpLocalize(option),
                          }}
                      />
                    </Typography>
                  }
                  labelplacement="right"
                />
              </Paper>
            </div>
          </Grid>
        </div>
      );
    })
  }

  renderColumns(columns) {
    return columns.map((col,idx) => (
      <div className='selection_container-column' key={idx}>
        { this.renderColumn(col) }
      </div>
    ))
  }

  render() {
    const enableKeyboardShortcuts = this.getEnableKeyboardShortcut()
    
    const columns = this.isMultipleColumns() ? this.getMultiColumns() : this.getSingleColumn();
    
    return (
      <FormGroup column>
        <Grid container spacing={1} wrap='nowrap'>
          { this.renderColumns(columns) }
        </Grid>
        {enableKeyboardShortcuts && (
          <KeyboardNavigation
            letterDown={this.keySelect}
            shiftDown={(value) => this.setState({ shiftPress: value })}
          />
        )}
      </FormGroup>
    );
  }
}

export default withStyles(useStyles)(CheckboxSelection);
