import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClipLoader } from "react-spinners";
import MiniProgress from "../components/Progress/MiniProgress";
import { isNumber, replaceLineBreaksHtml } from "./utils";
import { strpLocalize } from "../localization/localize";
import { isValid, parseISO } from "date-fns";
import _ from "lodash";

export const isLastQuestion = (questionIdx, survey) => {
  return questionIdx >= survey.questions.length - 1;
};

export const showDemographics = (survey) => {
  return (
    survey.demographics &&
    (survey.demographics.age ||
      survey.demographics.gender ||
      survey.demographics.hHIncome)
  );
};

export const getNextButtonContent = (
  questionState,
  buttonType,
  isLastQuestion,
  showDemographics,
  text = "Next"
) => {
  if (questionState.requestState === "POST_ATTEMPTED") {
    if (questionState.requestProgress !== undefined) {
      return <MiniProgress value={questionState.requestProgress * 100.0} />;
    }
    return (
      <div className="sweet-loading">
        <ClipLoader size={"15px"} color={"white"} loading={true} />
      </div>
    );
  }

  if (buttonType === "chevron") {
    return (
      <>
        {strpLocalize(text)}
        <FontAwesomeIcon
          size="lg"
          icon={faChevronRight}
          className="chevron-right"
        />
      </>
    );
  }

  const s3PostError = questionState.requestState === "POST_ERROR";
  if (s3PostError) return strpLocalize("Try again");

  if (isLastQuestion) {
    if (showDemographics) {
      return strpLocalize(text);
    } else {
      return strpLocalize("Finish");
    }
  } else {
    return strpLocalize(text);
  }
};

// Validates whether the current questoionState counts as a completed response.
// If it is not valid, returns truthy (a string with the error message).
// If it is valid, returns undefined.
export const validateResponses = (question, questionState) => {
  // Display questions don't require validation
  if (question.type === "DISPLAY") {
    return null;
  }
  // Object is empty or null.
  if (
    (!questionState.data && questionState.data !== 0) ||
    Object.entries(questionState.data).length === 0
  ) {
    return "Please provide a response.";
  }
  // Object entries are valid (for ex. text is not empty string)
  for (const key in questionState.data) {
    if (!questionState.data[key] && questionState.data[key] !== 0) {
      return "Please provide a response.";
    }
    switch (key) {
      case 'AUDIO':
        // TODO: validate the audio recording.
        break;
      case 'VIDEO':
      case 'SCREEN':
      case 'FILE':
        if (!questionState.data[key] || !questionState.data[key].size) {
          // TODO: validate the video recording.
          return false;
        }
        return null;

      case 'TEXT':
      case 'BACKUP_TEXT':
        if (questionState.data[key] === '') {
          return 'Please provide a response.';
        }
        break;
      case 'NUMBER':
        if (!isNumber(questionState.data[key])) {
          return 'Please enter a number.';
        }
        break;
      case 'DATE':
        if (!isValid(parseISO(questionState.data[key]))) {
          return 'Please provide a valid date.';
        }
        break;
      case 'SLIDER':
        break;
      case 'DROPDOWN':
      case 'SELECTION':
        if (!question.exclusive) {
          const everyValFalse = (el) => el === false;
          if (questionState.data[key].every(everyValFalse)) {
            return 'You must select at least one option.';
          }
        }
        if (question.minNumSelections) {
          const numSelected = _.filter(questionState.data[key]).length;
          if (numSelected < question.minNumSelections) {
            return `You must select at least ${question.minNumSelections} options.`;
          }
        }
        if (question.maxNumSelections) {
          // Additional selections above maxNumSelections are prohibited in the UI,
          // so this should not be possible. We check to be safe.
          const numSelected = _.filter(questionState.data[key]).length;
          if (numSelected > question.maxNumSelections) {
            return `You can only select up to ${question.maxNumSelections} options.`;
          }
        }
        // TODO: Exclusive selection validation
        break;
      case 'LIKERT':
        // In order to support display logic we need to account for whether a question is displayed or not
        const notFalseCount = _.filter(
          Object.values(this.props.questionState.data[key]),
          (val) => val !== false
        ).length;
        const displayedCount = _.filter(
          Object.values(this.props.question.options),
          (val) => val !== null
        ).length;

        if (displayedCount - notFalseCount > 0) {
          this.alert(strpLocalize('You must make one response per option.'));
          return false;
        }
        break;
      default:
        break;
    }
  }
  // Validate that the response types match the question.
  // The first is the usual verification, and the second
  // is if backup text is allowed.
  var REQUIRED_RESPONSE_TYPES = {
    AUDIO: [["AUDIO"], ["BACKUP_TEXT"]],
    VIDEO: [["VIDEO"], ["BACKUP_TEXT"]],
    SCREEN: [["SCREEN"], ["BACKUP_TEXT"]],
    SELECTION: [["SELECTION"], ["SELECTION"]],
    DROPDOWN: [["DROPDOWN"], ["DROPDOWN"]],
    TEXT: [["TEXT"], ["TEXT"]],
    NUMBER: [["NUMBER"], ["NUMBER"]],
    DATE: [["DATE"], ["DATE"]],
    SLIDER: [["SLIDER"], ["SLIDER"]],
    LIKERT: [["LIKERT"], ["LIKERT"]],
    RANKING: [["RANKING"], ["RANKING"]],
    AUDIO_SELECTION: [
      ["AUDIO", "SELECTION"],
      ["BACKUP_TEXT", "SELECTION"],
    ],
    VIDEO_SELECTION: [
      ["VIDEO", "SELECTION"],
      ["BACKUP_TEXT", "SELECTION"],
    ],
    AUDIO_TEXT: [
      ["AUDIO", "TEXT"],
      ["BACKUP_TEXT", "TEXT"],
    ],
    AUDIO_SLIDER: [
      ["AUDIO", "SLIDER"],
      ["BACKUP_TEXT", "SLIDER"],
    ],
    AUDIO_RANKING: [
      ["AUDIO", "RANKING"],
      ["BACKUP_TEXT", "RANKING"],
    ],
    THINKALOUD: [
      ["AUDIO", "TEXT"],
      ["BACKUP_TEXT", "TEXT"],
    ],
    THINKALOUD_CODE: [
      ["AUDIO", "TEXT"],
      ["BACKUP_TEXT", "TEXT"],
    ],
  };
  var qType = question.type;
  if (
    !question.allowBackupText &&
    !_.isEqual(
      REQUIRED_RESPONSE_TYPES[qType][0],
      Object.keys(questionState.data)
    )
  ) {
    let media = "media";
    if (qType.includes("AUDIO")) media = "audio";
    if (qType.includes("VIDEO") || qType.includes("SCREEN")) media = "video";
    return `Please provide either a ${media} and other response.`;
  }
  if (
    question.allowBackupText &&
    !REQUIRED_RESPONSE_TYPES[qType][1].every(
      (el) => Object.keys(questionState.data).indexOf(el) !== -1
    ) &&
    !REQUIRED_RESPONSE_TYPES[qType][0].every(
      (el) => Object.keys(questionState.data).indexOf(el) !== -1
    )
  ) {
    let media = "media";
    if (qType.includes("AUDIO")) media = "audio";
    if (qType.includes("VIDEO") || qType.includes("SCREEN")) media = "video";
    return `Please provide either a ${media} (or backup text) and other response.`;
  }
};

export const showPreviousButton = (survey, questionIdx) => {
  if (survey.hidePreviousButton) {
    return false;
  } else {
    return !(survey.removeWelcomeScreen && questionIdx === 0);
  }
};

export function separateParagraphs(text, disableDynamicTextSize = false) {
  if (!text) return null;
  text = replaceLineBreaksHtml(text);
  if (disableDynamicTextSize)
    return <div dangerouslySetInnerHTML={{ __html: strpLocalize(text) }} />;

  // Adjusting the font size:
  // Font size is in em, 1em being the "default" size. Formula is text.length < a ? 1.0 - (1-c)/(a-b)*(text.length-b) : c
  // where a is the length at which the text stops shrinking, b is the length at which the text starts shrinking, and
  // c is the minimum relative size for very long titles/subtitles. When length is < b or > a, font size is constant,
  // and font size decreases linearly from 1.0 to c when b < length < a
  return (
    <div
      style={{
        fontSize:
            "" +
            (text.length < 110 ? 1.0 - 0.00375 * (text.length - 30) : 0.7) +
            "em",
      }}
      dangerouslySetInnerHTML={{ __html: strpLocalize(text) }}
    />
  );
}

export const getSubTitle = (question, disableDynamicTextSize = false) => {
  if (!question) return null;
  if (question.subText)
    return separateParagraphs(
      strpLocalize(question.subText),
      disableDynamicTextSize
    );

  switch (question.type) {
    case "DISPLAY":
      return (
        <span style={{ marginBottom: 12 }}>
          {strpLocalize(question.subText)}
        </span>
      );

    case "AUDIO":
      return strpLocalize("Audio Response");

    case "VIDEO":
      return strpLocalize("Video Response");

    case "SCREEN":
      return strpLocalize("Click Next when you have completed the task");

    case "TEXT":
      return strpLocalize("Text Response");

    case "NUMBER":
      return strpLocalize("Enter a Number");
    case "DATE":
      return strpLocalize("Enter a Date");
    case "SLIDER":
      return strpLocalize("Drag the Slider");
    case "DROPDOWN":
      return strpLocalize("Select One");
    case "SELECTION":
      if (question.exclusive) return strpLocalize("Select One");
      return strpLocalize("Check All That Apply");

    case "AUDIO_SELECTION":
      if (question.exclusive)
        return strpLocalize(
          "Select one and use the microphone to explain your choice."
        );
      return strpLocalize(
        "Check all that apply and use the microphone to explain your choice."
      );

    case "AUDIO_TEXT":
      return strpLocalize(
        "Type a response and use the microphone to explain your choice."
      );

    case "AUDIO_SLIDER":
      return strpLocalize(
        "Drag the slider and use the microphone to explain your choice."
      );

    case "THINKALOUD":
      return strpLocalize(
        "The microphone is already recording. Speak your thoughts freely."
      );

    case "FILE":
      return strpLocalize("File Upload");

    case "LIKERT":
      return strpLocalize("Rate the following items.");

    case "RANKING":
      return strpLocalize("Rank the following items.");

    case "AUDIO_RANKING":
      return strpLocalize(
        "Rank the following items and use the microphone to explain your choice."
      );

    default:
      return;
  }
};
