import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const BorderLinearProgress = withStyles({
    root: {
        'z-index': 1,
        height: 16,
        borderRadius: 20,
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    bar: {
        borderRadius: 16,
    },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        overflow: 'hidden'
    },
    margin: {
        margin: theme.spacing(1),
    },
    progressBarTypography: (props) => ({
        position: 'absolute',
        left: props.calculateLeftOffset(theme.spacing(1), props.percentage),
        color: "white",
        fontSize: 10,
        'z-index': 2,
        transition: 'left 0.4s linear, transform 0.4s linear',
        transform: props.percentage == 0 ? 'translateX(0px)' : props.calculateTransform(theme.spacing(1), props.percentage),
    })
}));

export default function ProgressBarWithPercentage(props) {
    const percentage = props.value
    const classes = useStyles({percentage, calculateLeftOffset, calculateTransform});

    function calculateLeftOffset(defaultSpacing, percentage) {
        const defaultLeftOffset = defaultSpacing
        const leftOffset = percentage > 0 ? `${percentage}%` : defaultLeftOffset
        return leftOffset
    }

    function calculateTransform(defaultSpacing, percentage) {
        const defaultTranslateX = 0
        const translateX = percentage > 0 ? -1 * ((percentage / 100) * (3 * defaultSpacing) + (3 * defaultSpacing)) : defaultTranslateX
        return `translateX(${translateX}px)`
    }

    return (
        <div className={clsx(classes.root, "progress-bar")}>
            <Typography
                className={clsx(classes.progressBarTypography, classes.margin)}
                variant="body2"
                align="right">
                {Math.round(percentage)}%
            </Typography>
            <BorderLinearProgress
                className={classes.margin}
                variant="determinate"
                color="secondary"
                value={props.value}
            />
        </div>

    );
}
