import _ from "lodash";

export const TEXT_FORMAT_REGEX = /\$\{\S*\}/g; // matches ${...}
export const QN_REGEX = /^Q\d+\./; // matches Qn. where n is an integer
export const ANSWER_IDX_REGEX = /answer\[\d+\]/; // matches Qn[m] where n and m are integers

/*
  This function takes text and replaces the string formats (${Q1.answer}, ${condition}, etc.)
  with dynamic survey data pulled from the survey, questionState or conditions.
  - text: The text to replace piped text in.
  - survey: The survey object fetched from /surveys/<survey_id>.
  - questionState: The questionState object from redux.
  - conditions: The conditions dictionary from redux.
*/
export function insertPipedText(text, survey, questionState, conditions) {
  if (!text) return "";

  const replacePiped = (match) => {
    // Strip off outer ${ ... }
    let innerkey = match.slice(2, -1);

    // Test for question properties (Qn.prop)
    if (QN_REGEX.test(innerkey)) {
      let [Qn, field] = innerkey.split(".");
      let questionIdx = parseInt(Qn.slice(1)) - 1; // Qnm. -> nm
      let question = survey.questions[questionIdx];

      if (field === "answer" && questionIdx in questionState) {
        return convertQuestionStateDataToStr(
          questionState[questionIdx].data,
          question
        );
      } else if (ANSWER_IDX_REGEX.test(field)) {
        try {
          const optionIdx = getIntFromString(field);
          // Ranking types doesn't use an array of booleans, just the list of options.
          if (question.type === "RANKING") {
            return questionState[questionIdx].data["RANKING"][optionIdx];
          }
          // Selection or Dropdown type.
          else {
            const selectedOptions = question.options.filter(
              (o, i) =>
                questionState[questionIdx].data[question.type.split("_").pop()][
                  i
                ]
            );
            return optionIdx < selectedOptions.length
              ? selectedOptions[optionIdx]
              : match;
          }
        } catch (err) {
          console.error(err);
          return match;
        }
      }
      // Unsupported field.
      else {
        console.warn("Unsupported piped text question field: " + match);
        return match;
      }
    }
    // If not a question prop, then a condtion.
    else if (innerkey in conditions) {
      return conditions[innerkey];
    }
    // If nothing else, just return the original match. Could be a valid text collision.
    else {
      console.warn("No match found for piped text: " + match);
      return match;
    }
  };
  // Global regex so no need for replaceAll.
  return text.replace(TEXT_FORMAT_REGEX, replacePiped);
}

/*
  Converts the questionState.data dictionary of mixed types
  to a string for representing in piped text.
*/
function convertQuestionStateDataToStr(data, question) {
  if (_.isEmpty(data)) return "";
  switch (question.type) {
    case "TEXT":
    case "BACKUP_TEXT":
    case "NUMBER":
    case "SLIDER":
    case "DATE":
    case "RANKING":
      return data[question.type] || "";
    case "AUDIO_TEXT":
    case "AUDIO_SLIDER":
      return data[question.type.split("_")[1]] || "";
    case "SELECTION":
    case "DROPDOWN":
    case "AUDIO_SELECTION":
    case "AUDIO_DROPDOWN":
      return objectToStr(
        question.options.filter(
          (o, i) => data[question.type.split("_").pop()][i]
        )
      );
    default:
      return "";
  }
}

/*
  Generic util function that convert an object to a string.
  - If a string or number, returns the stringified version.
  - If an array, returns a comma separated string of the array elements.
  - If an object, returns a comma separated string of the object values.
  Works for nested objects, arrays and strings.
*/
export function objectToStr(obj) {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj.map((el) => objectToStr(el)).join(", ");
    } else {
      return Object.keys(obj)
        .map((key) => objectToStr(obj[key]))
        .join(", ");
    }
  } else {
    return obj;
  }
}

/*
  Takes an input string and removes anything non-int.
  For example "Q1[0]" -> "10" or "[-1]" -> "1"
*/
export function getIntFromString(str) {
  return parseInt(str.replace(/\D/g, ""));
}
