import React, { useEffect } from "react";

export default function CountdownTimer({ seconds, onComplete }) {
  const [timer, setTimer] = React.useState(seconds);

  useEffect(() => {
    if (timer === 0) {
      onComplete();
    } else {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer, onComplete]);

  return (
    <div className="countdown-timer">
      <p style={{ color: "white" }}>{timer}</p>
    </div>
  );
}
