import React, { Component } from "react";
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = (theme) => ({
  "surveyAlertTruex": {
    width: "50%",
    marginTop: "12px",
    marginBottom: "0px",
    marginLeft: "auto",
    marginRight: "auto",
  }
});

/**
 * This wrapper allows Alert to be opened imperatively. The parent needs a ref
 * on this component to call its method. If we ever switch to all function
 * components, a custom hook would be less clunky.
 */

const mapStateToProps = (state) => {
  return {
    align: state.survey && state.survey.align ? state.survey.align : "center",
  };
};

class StatusBar extends Component {
  state = { open: false, message: "" };

  timer = null;

  startCountdown = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, this.props.hideDuration);
  };

  trigger = (message) => {
    this.setState({ open: true, message }, this.startCountdown);
  };

  getSurveyAlertClass= (classes) => {
    return this.props.isTruex ? classes.surveyAlertTruex : "survey-alert"
  }

  render() {
    const { classes } = this.props;
    return (
      <Collapse
        in={this.state.open}
        className={clsx(this.getSurveyAlertClass(classes), this.props.align)}
      >
        <Alert severity="error" icon={false}>
          {this.state.message}
        </Alert>
      </Collapse>
    );
  }
}

StatusBar.defaultProps = {
  hideDuration: 2500,
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(withStyles(useStyles)(
  StatusBar
));
