import { Button, Chip } from "@material-ui/core";
import React, { Component } from "react";
import { strpLocalize } from "../../localization/localize";

class File extends Component {
  state = { filePreview: null, fileName: null };
  handleChange = (event) => {
    const newFiles = Object.values(event.target.files);
    let stagedFiles = { ...this.props.data };
    newFiles.forEach((file) => {
      let fileName = file.name;
      if (fileName in stagedFiles) fileName = file.name + " (Copy)";
      stagedFiles = {
        ...stagedFiles,
        [fileName]: file,
      };
    });
    this.props.onSubmit(stagedFiles);
  };

  handleDelete = (fileName) => {
    const stagedFiles = Object.fromEntries(
      Object.entries(this.props.data).filter(([key]) => key != fileName)
    );
    if (!Object.keys(stagedFiles).length)
      this.setState({
        filePreview: null,
        fileName: null,
      });
    this.props.onSubmit(stagedFiles);
  };

  loadPreview = () => {};

  componentDidUpdate() {
    if (
      this.props.data !== undefined &&
      !(this.state.fileName in this.props.data)
    ) {
      const fileNames = Object.keys(this.props.data);
      if (fileNames.length) this.setFilePreview(fileNames[0]);
    }
  }

  setFilePreview = (fileName) => {
    if (fileName in this.props.data) {
      this.setState({
        filePreview: URL.createObjectURL(this.props.data[fileName]),
        fileName: fileName,
      });
    }
  };

  promptUpload = (e) => {
    e.preventDefault();
    this.input.click();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            marginTop: 25,
            marginBottom: 30,
            borderStyle: "dashed",
            borderWidth: 2,
            borderColor: "#3A71DC",
            borderRadius: 6,
            paddingTop: "50",
          }}
        >
          {this.state.filePreview && (
            <div className="file-upload-image-preview">
              <img
                className="file-upload-image-preview"
                src={this.state.filePreview}
                alt={`Cannot show preview for ${this.state.fileName}`}
              />
              <p className="subtitle">
                {strpLocalize("Click on the file name to see preview")}
              </p>
            </div>
          )}
          <div className="staged-files">
            {this.props.data &&
              Object.keys(this.props.data).map((fileName) => (
                <Chip
                  label={fileName}
                  onDelete={this.handleDelete.bind(this, fileName)}
                  onClick={this.setFilePreview.bind(this, fileName)}
                />
              ))}
          </div>
          <form method="post" action="#" id="#">
            <div className="form-group files">
              <Button className="upload-button" onClick={this.promptUpload}>
                {strpLocalize("Choose File")}
              </Button>
              <input
                ref={(input) => (this.input = input)}
                type="file"
                multiple={true}
                label="files"
                className="form-control"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default File;
