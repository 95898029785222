import React from "react";
import clsx from "clsx";

const KeyboardKey = ({ onClick, pressed, children }) => {
  return (
    <div
      className={clsx(pressed && "main-button", "keyboard-key", "theme-color")}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default KeyboardKey;
