import React, { Component } from "react";
import {
  convertYoutubeLinkToEmbedded,
  convertGoogleDriveLinkToEmbedded,
} from "../utils/utils";
import { strpLocalize } from "../localization/localize";
class Stimuli extends Component {
  getStimuliJSX = () => {
    if (this.props.stimuli.type === "IMAGE" && this.props.stimuli.url) {
      return (
        <img
          alt="Stimulus provided by survey creator"
          style={{ maxWidth: "100%", maxHeight: "70vh" }}
          className="rounded"
          src={this.props.stimuli.url}
        />
      );
    } else if (this.props.stimuli.type === "AUDIO" && this.props.stimuli.url) {
      return (
        <audio
          controls
          style={{ width: "100%" }}
          className="rounded"
          autoPlay={this.props.stimuli.autoPlay}
          controlsList="nodownload"
        >
          <source src={this.props.stimuli.url} />
          {"Your browser does not support audio playback."}
        </audio>
      );
    } else if (this.props.stimuli.type === "VIDEO" && this.props.stimuli.url) {
      return (
        <video
          controls
          style={{ width: "100%" }}
          className="rounded"
          autoPlay={this.props.stimuli.autoPlay}
          controlsList="nodownload"
        >
          <source src={this.props.stimuli.url} />
          {"Your browser does not support video playback."}
        </video>
      );
    } else if (
      this.props.stimuli.type === "YOUTUBE" &&
      this.props.stimuli.url
    ) {
      return (
        <div style={{ width: "100%", height: "50vh" }} className="rounded">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={convertYoutubeLinkToEmbedded(this.props.stimuli.url)}
            title="Video Stimulus"
          ></iframe>
        </div>
      );
    } else if (
      this.props.stimuli.type === "GOOGLE_DRIVE" &&
      this.props.stimuli.url
    ) {
      return (
        <div style={{ width: "100%", height: "50vh" }} className="rounded">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={convertGoogleDriveLinkToEmbedded(this.props.stimuli.url)}
            title="Video Stimulus"
          ></iframe>
        </div>
      );
    } else if (this.props.stimuli.type === "IFRAME" && this.props.stimuli.url) {
      return (
        <div
          style={{ width: "100%", height: "50vh", marginBottom: 12 }}
          className="rounded"
        >
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ borderRadius: 8 }}
            src={this.props.stimuli.url}
            title="URL Stimuli"
          ></iframe>
        </div>
      );
    } else if (
      this.props.stimuli.type === "HTML" &&
      this.props.stimuli.htmlContent
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: strpLocalize(this.props.stimuli.htmlContent),
          }}
        />
      );
    }
  };
  render() {
    return (
      <div className="stimuli-container">
        <div
          className="stimuli rounded"
          style={{
            display:
              this.props.stimuli.type === "IMAGE" ? "inline-block" : null,
            margin: 0,
          }}
        >
          {this.getStimuliJSX()}
        </div>
      </div>
    );
  }
  7;
}

export default Stimuli;
