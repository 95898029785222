import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

class Code extends Component {
  state = {
    value:
      "# The interviewer has specified they'd like you to code in Python\n\n" +
      "def merge_sort(input):\n" +
      "    # Begin here\n\n" +
      "assert main([3, 5, 2, 6, 7, 8]) == [2, 3, 5, 6, 7, 8]",
  };
  handleChange = (newValue) => {
    this.setState({
      value: newValue,
    });
  };

  onSubmit = () => {
    console.log(this.state.value);
    this.props.onSubmit(this.state.value);
  };

  render() {
    return (
      <div>
        <Paper style={{ marginBottom: "20px" }}>
          <AceEditor
            mode="python"
            theme="monokai"
            width="100%"
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.onSubmit}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
          />
        </Paper>
      </div>
    );
  }
}

export default Code;
