import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { strpLocalize } from "../../localization/localize";

// We can inject some CSS into the DOM.
const styles = {
  root: {
    marginTop: "5vh",
    fontSize: "16px",
  },
  button: {
    textDecoration: "none",
    background: "none",
    fontSize: 14,
    padding: 0,
    border: "none",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    color: "inherit",
  },
};

function HelpText(props) {
  const { classes, className, onClick } = props;

  return (
    <div className={clsx(classes.root, className, "subtitle")}>
      <button className={classes.button} onClick={onClick}>
        {strpLocalize("Having a technical problem?")}
      </button>
    </div>
  );
}

HelpText.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(HelpText);
