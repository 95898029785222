import React, { useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { strpLocalize } from "../../localization/localize";
import KeyboardNavigation from "../Keyboard/KeyboardNavigation";
import KeyboardButton from "./KeyboardButton";
import KeyboardKey from "../Keyboard/KeyboardKey";
import KeyboardShortcutInfo, { COMMAND_KEY, SHIFT_KEY } from "../Keyboard/KeyboardShortcutInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    width: 64,
    marginLeft: 5,
    fontSize: 16,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#E45049",
    color: "white",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FA544B",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 8,
      height: 50,
      width: 50,
      fontSize: 18,
    },
  },
}));

const ShortcutTip = ({ shiftPress, cmdPress, playPress, playing }) => {
  return (
    <KeyboardShortcutInfo action={playing ? "pause":"play"}>
      <KeyboardKey pressed={shiftPress}>{SHIFT_KEY}</KeyboardKey>
      <KeyboardKey pressed={cmdPress}>{COMMAND_KEY}</KeyboardKey>
      <KeyboardKey pressed={playPress}> P </KeyboardKey>
    </KeyboardShortcutInfo>
  );
};

function GeneralButton({ className, children, playing, ...other }) {
  const classes = useStyles();
  const buttonRef = useRef(null);

  const [shiftPress, setShiftPress] = useState(false);
  const [cmdPress, setCmdPress] = useState(false);
  const [playPress, setPlayPress] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const text = strpLocalize("Play Back Recording");

  const playback = (event) => {
    if (buttonRef.current && buttonRef.current.keyboardClick)
      buttonRef.current.keyboardClick(event, () => setPlayPress(false));
  };

  const tooltipProps = {
    title: (
      <ShortcutTip
        shiftPress={shiftPress}
        cmdPress={cmdPress}
        playPress={playPress}
        playing={playing}
      />
    ),
    placement: "right",
    open: shiftPress || isHovering,
  };

  return (
    <>
      <KeyboardButton
        className={clsx(classes.root, className, "record-button")}
        aria-label={text}
        ref={buttonRef}
        {...other}
        tooltipProps={tooltipProps}
        tooltipAriaLabel={strpLocalize("Play Back Recording")}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
      </KeyboardButton>
      <KeyboardNavigation
        playDown={playback}
        shiftDown={setShiftPress}
        cmdDown={setCmdPress}
        letterDown={(key) => setPlayPress(["p", "P"].includes(key))}
      />
    </>
  );
}

export default GeneralButton;
