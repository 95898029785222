import React from "react";
import { connect } from "react-redux";
import { clearToast } from "../../redux/actions";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const mapStateToProps = (state) => {
  return {
    toastOpen: state.toastOpen,
    toastMessage: state.toastMessage,
  };
};

const mapDispatchToProps = { clearToast };

const GlobalToast = ({ toastOpen, toastMessage, clearToast }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={toastOpen}
      autoHideDuration={5000}
      aria-describedby="global message"
      message={toastMessage}
      onClose={clearToast}
    >
      <Alert severity="error" icon={false} elevation={5} variant="filled">
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalToast);
