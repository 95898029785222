import _ from "lodash";
import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Paper from "@material-ui/core/Paper";
import { fixedShuffleIndex } from "../../utils/utils";
import getDisplayableOptions from "../../utils/questionDisplayableOptions";
import { strpLocalize } from "../../localization/localize";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import KeyboardNavigation from "../../components/Keyboard/KeyboardNavigation";
import KeyboardKey from "../../components/Keyboard/KeyboardKey";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = (theme) => ({
  "noIconSelectionSvg": {
    "& span": {
      display: "none",
    }
  },
  "noIconSelectionPaper": {
    borderRadius: "16px",
    minHeight: "42px",
    display: "flex",
  },
  "noKeyboardShortcutSelectionPaper": {
    "margin-left": "0px"
  },
  "truexSelectionPaper": {
    borderRadius: "14px",
    minHeight: "28px",
    display: "flex",
    paddingLeft: "20px"
  },
  "truexLabel": {
    fontSize: "14px"
  },
  "truexRadio": {
    padding: "2px",
  },
  "truexGridSpacings": {
    paddingLeft: "4px",
    paddingRight: "4px",
  }
});

class RadioSelection extends Component {
  state = { arr: [], options: [], shiftPress: false };

  componentWillMount() {
    if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ options: shuffledOptions }, this.populateArr);
    } else {
      this.setState({ options: this.props.question.options }, this.populateArr);
    }
  }

  populateArr = () => {
    // Fill the select arr with false values, or populate from the saved state data arr if provided.
    var arr = new Array(this.props.question.options.length).fill(false);
    var optionMap = {};
    if (this.props.data) {
      for (let i = 0; i < arr.length; i++) {
        if (this.props.data[i]) {
          arr[
            this.getShuffledOptionsIndex(this.props.question.options[i])
          ] = true;
        }
      }
    }
    this.setState({
      arr: arr,
      optionMap: optionMap,
    });
  };

  getShuffledOptionsIndex = (option) => {
    return this.state.options.indexOf(option);
  };

  getRealOptionIndex = (option) => {
    return this.props.question.options.indexOf(option);
  };

  selectOption = (optionIdx) => {
    if (optionIdx >= this.state.options.length) return;
    var displayArr = new Array(this.props.question.options.length).fill(false);
    var submitArr = new Array(this.props.question.options.length).fill(false);

    var realIndex = this.getRealOptionIndex(this.state.options[optionIdx]);

    displayArr[optionIdx] = true;
    submitArr[realIndex] = true;

    this.props.onSubmit(submitArr);
    this.setState({ arr: displayArr });
  };

  arrowSelect = (event) => {
    const numOptions = this.state.options.length;
    if (event && event.key === "ArrowUp") {
      const optionIdx = this.state.arr.indexOf(true);
      this.selectOption(optionIdx > 0 ? optionIdx - 1 : numOptions - 1);
    }
    if (event && event.key === "ArrowDown") {
      const optionIdx = this.state.arr.indexOf(true);
      this.selectOption(optionIdx >= 0 ? (optionIdx + 1) % numOptions : 0);
    }
  };

  handleChange = (event) => {
    this.selectOption(this.getShuffledOptionsIndex(event.target.value));
  };

  keySelect = (key) => {
    if (this.getEnableKeyboardShortcut() && key && !this.state.shiftPress) {
      const optionIdx = key.toLowerCase().charCodeAt(0) - 97;
      this.selectOption(optionIdx);
    }
  };

  getEnableKeyboardShortcut() {
    return !(this.props.disableShortcuts || this.props.disableKeySelection) && !this.props.question.randomize;
  }

  getSelectedClass = (idx) => {
    return this.state.arr[idx] ? "selected" : "";
  };

  getTruexFontSize = (classes) => {
    return this.props.isTruex ? classes.truexLabel : "";
  }

  getTruexRadio = (classes) => {
    return this.props.isTruex ? classes.truexRadio : "";
  }

  getSelectionPaperNoIconClass = (classes) => {
    return this.props.isTruex
      ? classes.truexSelectionPaper
      : (this.props.question && this.props.question.showSelectionIcons === false)
        ? classes.noIconSelectionPaper
        : ""
  };

  getRadioNoIconClass = (classes) => {
    return (this.props.question && this.props.question.showSelectionIcons === false) ? classes.noIconSelectionSvg : "";
  };

  getNoKeyboardShortcutClass = (classes) => {
    return (this.props.disableShortcuts || this.props.disableKeySelection) ? classes.noKeyboardShortcutSelectionPaper : "";
  }

  isMultipleColumns() {
    const displayable = this.displayOptions();
    return this.props.isTruex && displayable && Object.keys(displayable).length > 5
  }

  getMultiColumns() {
    const displayable = this.displayOptions();

    const totalCols = 2;
    const optionKeys = Object.keys(displayable)
    const totalOptions = optionKeys.length;
    const totalOptionsInCol = Math.ceil(totalOptions/totalCols)
    const options = []

    let i = 0;
    for(let col = 0; col < totalCols; col++) {
      const slice = optionKeys.slice(i, i + totalOptionsInCol)
      const colOpts = _.pickBy(displayable, (val, key) => _.includes(slice, key));
      options.push(colOpts);

      i = i + totalOptionsInCol
    }

    return options
  }

  getSingleColumn() {
    return [this.displayOptions()]
  }

  displayOptions() {
    const { question } = this.props;

    let colOpts = {}
    this.state.options.forEach((option, idx) => colOpts[idx] = option);
    
    if(!question.optionsDisplayConditions) return colOpts;

    const optionsDisplay = getDisplayableOptions(
      this.props.questionsState, 
      this.props.survey.questions, 
      question,
      this.props.preview
    );

    colOpts = _.pickBy(
      colOpts,
      (_v, idx) => optionsDisplay[idx]
    )

    return colOpts
  }

  renderColumn(col) {
        const { classes } = this.props;
    const enableKeyboardShortcuts = this.getEnableKeyboardShortcut();
    const indeces = Object.keys(col);

    return indeces.map(idx => {
      const option = col[idx];
      const currentIdx = this.getRealOptionIndex(option);

      return (
        <div key={idx} className="selection-option-container">
          <Grid container direction='row' spacing={0}>
            {
              enableKeyboardShortcuts && <div style={{ margin: 'auto 0', width: '40px' }}>
                <KeyboardKey
                  pressed={this.state.arr[idx]}
                  onClick={() => this.selectOption(currentIdx)}
                >
                  {String.fromCharCode(currentIdx + 65)}
                </KeyboardKey>
              </div>
            }
            <div style={{ 'flex-grow': '1' }}>
              <Paper
                key={`radio-selection-${idx}`}
                className={clsx("selection-paper",
                                this.getSelectedClass(idx),
                                this.getSelectionPaperNoIconClass(classes),
                                this.getNoKeyboardShortcutClass(classes))
                          }
                square
              >
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Radio
                      className={clsx("selection", this.getRadioNoIconClass(classes), this.getTruexRadio(classes))}
                      checked={this.state.arr[idx]}
                      onChange={(e) => this.handleChange(e)}
                      value={option}
                      color="primary"
                      inputProps={{ "aria-label": "primary" }}
                    />
                  }
                  label={
                    <Typography variant="body1" className={this.getTruexFontSize(classes)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: strpLocalize(option),
                        }}
                      />
                    </Typography>
                  }
                  labelplacement="right"
                />
              </Paper>
            </div>
          </Grid>
        </div>
      );
    })
  }

  renderColumns(columns) {
    return columns.map((col,idx) => (
      <div className='selection_container-column' key={idx}>
        { this.renderColumn(col) }
      </div>
    ))
  }

  render() {
    const enableKeyboardShortcuts = this.getEnableKeyboardShortcut();
    const columns = this.isMultipleColumns() ? this.getMultiColumns() : this.getSingleColumn();

    return (      
      <RadioGroup>
        <Grid container spacing={1} wrap='nowrap'>
          { this.renderColumns(columns) }
        </Grid>
        {enableKeyboardShortcuts && (
          <KeyboardNavigation
            letterDown={this.keySelect}
            shiftDown={(value) => this.setState({ shiftPress: value })}
            arrowDown={this.arrowSelect}
          />
        )}
      </RadioGroup>
    );
  }
}

export default withStyles(useStyles)(RadioSelection);
