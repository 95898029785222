import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import StatusBar from "../components/Alerts/StatusBar";
import Check from "../components/Help/Check";
import Close from "../components/Help/Close";
import { Grid, Typography } from "@material-ui/core";
import { Recorder } from "../recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faPlayCircle,
  faPauseCircle,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import RecordButton from "../components/Buttons/RecordButton";
import { strpLocalize } from "../localization/localize";
import { getDarkColor, addHexTransparency } from "../colors";
import clsx from "clsx";
import { secondsToString, getAlignValue, getBrowserInfo } from "../utils/utils";
import KeyboardNavigation from "../components/Keyboard/KeyboardNavigation";
import TransparentTooltip from "../components/Help/TransparentTooltip";
import KeyboardKey from "../components/Keyboard/KeyboardKey";

var WaveSurfer = require("wavesurfer.js");

class MicCheck extends Component {
  state = {
    isHovered: true,
    isRecording: false,
    timeElapsed: 0,
    somethingRecorded: false,
    playing: false,
    testingIfRecordingSupported: true,
  };

  statusRef = createRef();

  componentDidMount() {
    this.recorder = new Recorder(this.props.audioRecordingFormat);
    this.$el = ReactDOM.findDOMNode(this);
    this.$waveform = this.$el.querySelector(".mic-check-wave");
    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      barWidth: 2,
      barRadius: 3,
      height: 28,
      barMinHeight: 2,
      cursorWidth: 1,
      cursorColor: null,
      waveColor: addHexTransparency(getDarkColor(this.props.theme)),
      progressColor: getDarkColor(this.props.theme),
    });

    setTimeout(() => {
      this.setState({
        recordingSupported: getBrowserInfo().recordingSupported,
        testingIfRecordingSupported: false,
      });
    }, 1000);
  }

  loadWavesurfer = (file) => {
    this.wavesurfer.loadBlob(file);
  };

  getRecorderText = () => {
    const showTime = this.state.isRecording || this.state.somethingRecorded;

    return (
      <p
        className={clsx(
          "record-time-text",
          this.props.survey.align === "left" ? "ml-0" : "ml-auto",
          this.props.survey.align === "right" ? "mr-0" : "mr-auto"
        )}
        style={{ visibility: showTime ? "visible" : "hidden" }}
      >
        {secondsToString(this.state.timeElapsed)}
      </p>
    );
  };

  getRecordButtonText = () => {
    if (this.state.isRecording) return strpLocalize("Stop Recording");
    if (!this.state.somethingRecorded)
      return (
        <>
          <FontAwesomeIcon icon={faMicrophone} />
          <span style={{ marginLeft: 10 }}>{strpLocalize("Record")}</span>
        </>
      );
    return strpLocalize("Re-record");
  };

  getPlayPauseText() {
    if (!this.state.somethingRecorded || !this.wavesurfer) return;
    if (this.state.playing) return <FontAwesomeIcon icon={faPauseCircle} />;
    return <FontAwesomeIcon icon={faPlayCircle} />;
  }

  toggleAudioRecording = () => {
    if (this.state.isRecording) {
      // Stop Recording
      clearInterval(this.micTimer);

      this.recorder
        .stop()
        .then((file) => {
          this.setState({ somethingRecorded: true, isRecording: false });
          this.loadWavesurfer(file);
        })
        .catch((e) => {
          this.alert(strpLocalize("We could not record your message."));
          console.error(e);
        });
    } else {
      this.recorder
        .start()
        .then(() => {
          this.setState({
            startTime: Date.now(),
            timeElapsed: 0,
            somethingRecorded: false,
          });
          this.micTimer = setInterval(
            () =>
              this.setState({
                timeElapsed: this.state.timeElapsed + 1,
              }),
            1000
          );
          this.setState({ isRecording: true });
        })
        .catch((e) => {
          console.error(e);
          this.alert(strpLocalize("Could not start recording."));
        });
    }
  };

  toggle = (event) => {
    event.stopPropagation();
    if (this.state.playing) {
      this.wavesurfer.pause();
    } else {
      this.wavesurfer.play();
    }
    this.setState({ playing: !this.state.playing });
  };

  alert = (message) => {
    this.statusRef.current.trigger(message);
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        justifyContent={getAlignValue(
          this.props.survey.align,
          "justifyContent"
        )}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2" className="title" gutterBottom>
            {strpLocalize("Microphone Check")}
          </Typography>
          <h2 className="subtitle">
            <span>
              {this.state.testingIfRecordingSupported && (
                <>
                  <span style={{ marginRight: 6 }}>
                    <ClipLoader
                      size={"12px"}
                      color={getDarkColor(this.props.theme)}
                      loading={true}
                    />
                  </span>
                  <span className="microphone-check-text">
                    {strpLocalize("Checking browser support...")}
                  </span>
                </>
              )}
              {this.state.recordingSupported === true && (
                <>
                  <span style={{ marginRight: 6 }}>
                    <Check />
                  </span>
                  <span className="microphone-check-text">
                    {strpLocalize("Your browser is supported.")}
                  </span>
                </>
              )}
              {this.state.recordingSupported === false && (
                <>
                  <span style={{ marginRight: 6 }}>
                    <Close />
                  </span>
                  <span className="microphone-check-text">
                    {strpLocalize("Your browser is not supported.")}
                  </span>
                </>
              )}
            </span>
          </h2>
          {this.getRecorderText()}
          <div>
            <RecordButton
              onClick={this.toggleAudioRecording}
              isRecording={this.state.isRecording}
            >
              {this.getRecordButtonText()}
            </RecordButton>
          </div>
        </Grid>
        <Grid item xs={10} sm={8} md={6}>
          <div className="audio-container">
            <div className="play-button-container">
              <TransparentTooltip
                title={
                  <div className={this.props.theme}>
                    <span className="theme-color">
                      {strpLocalize("Press")}{" "}
                    </span>
                    <KeyboardKey>P</KeyboardKey>
                    <span className="theme-color">
                      {" "}
                      {strpLocalize("to play")}
                    </span>
                  </div>
                }
                aria-label={strpLocalize("Play")}
                placement="left"
              >
                <span
                  onClick={this.toggle}
                  className={"play-pause-span"}
                  style={{ color: getDarkColor(this.props.theme) }}
                >
                  {this.getPlayPauseText()}
                </span>
              </TransparentTooltip>
              <KeyboardNavigation playDown={this.toggle} />
            </div>
            <div className="waveform-inner-container">
              <div className="waveform">
                <div className="mic-check-wave" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <StatusBar ref={this.statusRef} />
          <PrevButton
            className="on-main-button-left"
            onClick={() => {
              if (this.state.isRecording) {
                this.alert(strpLocalize("Please stop recording first."));
              } else {
                this.props.prevStep();
              }
            }}
          >
            {this.props.survey.buttonType === "chevron" && (
              <>
                <FontAwesomeIcon
                  size="lg"
                  icon={faChevronLeft}
                  className="chevron-left"
                />
                {strpLocalize("Previous")}
              </>
            )}
          </PrevButton>
          <MainButton
            className="next-button"
            onClick={() => {
              if (this.state.isRecording) {
                this.alert(strpLocalize("Please stop recording first."));
              } else {
                this.props.nextStep();
              }
            }}
          >
            <span>
              {strpLocalize("Next")}
              {this.props.survey.buttonType === "chevron" && (
                <FontAwesomeIcon
                  size="lg"
                  icon={faChevronRight}
                  className="chevron-right"
                />
              )}
            </span>
          </MainButton>
        </Grid>
      </Grid>
    );
  }
}

export default MicCheck;
