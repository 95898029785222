import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import RecordButton from "../../components/Buttons/RecordButton";
import RedirectButton from "../../components/Buttons/RedirectButton";
import Timer from "../../components/Recording/Timer";
import DisconnectedView from "../../components/Recording/DisconnectedView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faClock } from "@fortawesome/free-solid-svg-icons";
import { strpLocalize } from "../../localization/localize";
import RecordRTC from "recordrtc";
import { formatUrl, getAlignValue } from "../../utils/utils";
class Screen extends Component {
  state = {
    videoSrc: null,
    recording: false,
    somethingRecorded: false,
    connected: false,
  };
  recording = false;
  timeAllowedRef = React.createRef(null);
  timeAllowedRef2 = React.createRef(null);
  componentWillUnmount() {
    // Hide the stream sharing notice when you move onto the next question.
    this.relinquishStreamIfExists();
  }

  relinquishStreamIfExists() {
    if (this.stream && this.stream.getTracks())
      this.stream.getTracks().forEach((track) => track.stop());
  }

  addStreamStopListener(stream, callback) {
    stream.addEventListener(
      "ended",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.addEventListener(
      "inactive",
      function () {
        callback();
        callback = function () {};
      },
      false
    );
    stream.getTracks().forEach(function (track) {
      track.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      track.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
    });
  }

  async getScreenStream() {
    // Relinquish the old stream before requesting a new one.
    this.relinquishStreamIfExists();

    // Secure the screen stream.
    if (navigator.getDisplayMedia) {
      return await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      return await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    } else {
      return await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }
  }
  async getAudioStream() {
    let audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    return await audioStream.getTracks()[0];
  }

  setupScreenCapture(stream) {
    this.recorder = new RecordRTC(stream, {
      type: "video",
      mimeType: "video/webm",
    });
    if (this.props.videoParams.maxResponseLength) {
      this.recorder
        .setRecordingDuration(
          parseInt(this.props.videoParams.maxResponseLength, 10) * 1000
        )
        .onRecordingStopped(this.stoppedByTime);
    }
  }

  stoppedByTime = () => {
    this.stopRecordingCallback();

    try {
      this.timeAllowedRef.current.classList.add("stopped-recording");
      this.timeAllowedRef2.current.classList.add("stopped-recording2");
      setTimeout(() => {
        this.timeAllowedRef.current.classList.remove("stopped-recording");
      }, 160);
    } catch (e) {
      console.log(e.message);
    }
  };

  stopRecordingCallback = () => {
    this.setState({ recording: false });
    var blob = this.recorder.getBlob();
    blob.name = "response_screen.webm";

    this.setState({
      videoSrc: URL.createObjectURL(blob),
      somethingRecorded: true,
    });
    this.props.videoParams.onStopRecording();
    this.props.onSubmit(blob);
  };

  stopRecording = () => {
    this.recorder.stopRecording(this.stopRecordingCallback);
  };

  captureScreen = async () => {
    const stream = await this.getScreenStream();
    const audioStream = await this.getAudioStream();
    stream.addTrack(audioStream);
    this.stream = stream;

    // If the user clicks "stop sharing" we stop recording.
    this.addStreamStopListener(stream, () => {
      this.stopRecording();
      this.setState({ connected: false });
    });
    this.setupScreenCapture(stream);
    this.setState({ connected: true });
  };

  toggleRecording = async () => {
    if (!this.state.recording) {
      await this.captureScreen();
      this.recorder.startRecording();
      this.props.videoParams.onStartRecording();
      this.setState({ recording: true });
      if (
        this.timeAllowedRef2.current &&
        this.timeAllowedRef2.current.classList.contains("stopped-recording2")
      ) {
        this.timeAllowedRef2.current.classList.remove("stopped-recording2");
      }
    } else {
      this.stopRecording();
    }
    this.render();
  };

  getRecordButtonText = () => {
    if (this.state.recording) return strpLocalize("Stop Recording");
    if (!this.state.somethingRecorded) {
      return (
        <>
          <FontAwesomeIcon icon={faDesktop} />
          <span style={{ marginLeft: 10 }}>{strpLocalize("Record")}</span>
        </>
      );
    }
    return strpLocalize("Re-record");
  };

  showRecorderVideo() {
    /* This <video> is used for playback to the user before and during recording. */
    return (
      this.state.connected &&
      (this.state.recording || !this.state.somethingRecorded)
    );
  }

  redirectToLink() {
    window.open(this.props.question.redirectLink);
  }

  render() {
    const marginLeft = this.props.align === "left" ? 0 : "auto";
    const marginRight = this.props.align === "right" ? 0 : "auto";

    return (
      <div className={this.props.align}>
        {this.props.videoParams.maxResponseLength && (
          <div
            className="time-allowed"
            justifyContent={getAlignValue(this.props.align, "justifyContent")}
            ref={this.timeAllowedRef2}
            style={{ marginLeft, marginRight }}
          >
            <FontAwesomeIcon icon={faClock} />
            &nbsp;{strpLocalize("Time Limit")}
          </div>
        )}
        <div
          style={{
            backgroundColor:
              this.state.connected || this.state.somethingRecorded
                ? "inherit"
                : "rgba(255, 255, 255, 0.4)",
            marginLeft,
            marginRight,
          }}
          className="screen-wrapper rounded"
        >
          {!this.state.connected && !this.state.somethingRecorded && (
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <DisconnectedView
                message={
                  <div
                    className="flex items-center message-wrapper"
                    style={{ height: "100%" }}
                  >
                    To record video, your browser may request access to your
                    camera and microphone.
                  </div>
                }
              />
            </div>
          )}
          {this.state.recording && (
            <Timer
              timeLimit={
                parseInt(this.props.videoParams.maxResponseLength, 10) * 1000
              }
            />
          )}

          {!this.state.recording && this.state.somethingRecorded && (
            /* This <video> is used to loop the recording after completion. */
            <video
              key={`SCREEN-${this.props.question.questionId}-PLAYBACK`}
              playsInline
              style={{ width: "100%" }}
              src={this.state.videoSrc}
              controls={true}
              className="rounded"
            />
          )}
        </div>
        <Grid
          container
          justifyContent={getAlignValue(this.props.align, "justifyContent")}
          alignItems="center"
          style={{ minHeight: 95 }}
        >
          <Grid item>
            <RecordButton
              onClick={this.toggleRecording}
              ref={this.timeAllowedRef}
              className="ripple-base"
              isRecording={this.state.recording}
            >
              {this.getRecordButtonText()}
            </RecordButton>
          </Grid>
          <Grid item>
            {this.props.question && this.props.question.redirectLink && (
              <RedirectButton
                onClick={() => {
                  window.open(formatUrl(this.props.question.redirectLink));
                }}
              >
                {strpLocalize("Visit Link")}
              </RedirectButton>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Screen;
