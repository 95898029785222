import React from "react";
import { connect } from "react-redux";
import Question from "./Question";
import QuestionWithFrame from "./QuestionWithFrame";

const mapStateToProps = (state) => ({
  preview: state.preview,
  question: state.question,
  questionState: state.questionState[state.questionIdx],
  survey: state.survey,
  questionIdx: state.questionIdx,
});

const QuestionView = (props) => {
  const currentMission = props.question && props.question.groupId;
  const viewSelector = () => {
    if (currentMission) return <QuestionWithFrame />;
    else return <Question />;
  };

  return viewSelector();
};

export default connect(mapStateToProps)(QuestionView);
