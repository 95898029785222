import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

export const getTheme = (font) => {
  let fontFamily = "Lato, sans-serif";
  switch (font) {
    case "serif":
      fontFamily = "Georgia, Times New Roman, serif";
      break;
    case "opensans":
      fontFamily = "Open Sans, sans-serif";
      break;
    case "mulish":
      fontFamily = "Mulish, sans-serif";
      break;
    case "inter":
      fontFamily = "Inter, sans-serif";
      break;
    case "plain":
    default:
      break;
  }

  const baseTheme = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 961,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      h1: {
        fontSize: 60,
      },
      h2: {
        fontSize: 32,
      },
      h3: {
        fontSize: 20,
      },
      fontFamily,
    },
    palette: {
      primary: {
        main: "#757575",
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          "@media (max-width: 960px)": {
            fontSize: 42,
          }
        },
        h2: {
          "@media (max-width: 960px)": {
            fontSize: 22,
          }
        },
        h3: {
          "@media (max-width: 960px)": {
            fontSize: 14,
          }
        },
      },
      MuiButton: {
        root: {
          borderRadius: 12,
          fontFamily: "Lato, sans-serif",
          minWidth: 50,
        },
        label: {
          textTransform: "none",
        },
      },
      MuiFormLabel: {
        root: {
          fontFamily: "Lato, sans-serif",
        },
      },
      MuiFormControlLabel: {
        label: {
          fontFamily: "Lato, sans-serif",
          "@media (max-width: 599px)": {
            fontSize: 14,
          },
        },
      },
      MuiSkeleton: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.11)",
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: "Lato, sans-serif",
          fontSize: "1.1rem",
        },
      },
      MuiTooltip: {
        tooltip: {
          fontFamily: "Lato, sans-serif",
        },
      },
    },
  };

  return createTheme(baseTheme);
};
