import React from "react";

import clsx from "clsx";
import "../../assets/css/checkmark.css";

// This class aniamtes a checkmark in a circle when initially rendered.
// The className prop can either be set to 'large' or 'small'.
export default function Checkmark(className) {
  return (
    <>
      <svg
        className={clsx("checkmark", className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </>
  );
}
