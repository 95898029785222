/**
 * Segment Analytics tracking
 * Simple helper functions for accessing the Segment API.
 */

export function trackPage(pageName, metadata) {
  window.analytics.page(pageName, metadata);
}

export function track(eventName, metadata) {
  window.analytics.track(eventName, metadata);
}
