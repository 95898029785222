import React from "react";
import _ from "lodash";

import { Slider, Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { strpLocalize } from "../../../localization/localize";
import { isMobileDevice } from "../../../utils/utils";

const StyledSlider = withStyles({
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#494949",
    marginTop: -6,
    marginLeft: -12,
    "&:focus, &:hover, &:active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: "#494949",
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function dimensionSlider(dimension, props) {
  const { columns, responses, updateResponses } = props;

  const cols = columns[dimension.index].values;
  const labels = columns[dimension.index].labels;
  
  const defaultValue = Math.floor((cols.length - 1)/2);
  const value = responses[dimension.index] === false ? defaultValue : cols.indexOf(responses[dimension.index]);
  const totalCols = props.columns.map(c => c.values.length).sort()[props.columns.length - 1]

  return(
    <tr key={dimension.index}>
      {
        props.labels.position !== 'none' && (
          <td key={dimension.index} className={props.classes.rowHeader}>{ strpLocalize(dimension.name) }</td>
        )
      }
      <td colSpan={totalCols}>
        <div style={{ padding: '0 25px' }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <StyledSlider
                size='small'
                aria-labelledby="discrete-slider-small-steps"
                step={1}
                min={0}
                max={cols.length - 1}
                valueLabelDisplay='off'
                value={value}
                onChange={(_e, colIdx) => {
                  const value = cols[colIdx];
                  const newResponses = [...props.responses];
                  newResponses[dimension.index] = value;
                  updateResponses(newResponses, true);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align="left">
                  <div className={props.classes.labels} style={{ left: '-15px' }}>{ labels[0] }</div>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">
                <div className={props.classes.labels} style={{ right: '-15px' }}>{ labels[labels.length - 1] }</div>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </td>
    </tr>
  )
}

function LikertSlider(props) {
  const totalCols = props.columns.map(c => c.values.length).sort()[props.columns.length - 1]

  return (
    <table>
      <thead>
        { _.range(props.labels.position !== 'none' ? 0 : 1, totalCols).map(label => <th></th>) }
      </thead>
      <tbody>
        {/* ADX-1044 - skip row when name === null b/c its not meant to render b/c display logic  */}
        { props.rows.map((dimension) => {if (dimension.name !== null) return dimensionSlider(dimension, props)}) }
      </tbody>
    </table>
  )
}

const useStyles = ({typography}) => ({
  "labels": {
    color: typography.h3.color,
    fontSize: isMobileDevice() ? '10px' : '15px'
  }
});

export default withStyles(useStyles)(LikertSlider);