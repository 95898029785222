import React from "react";
import Typography from "@material-ui/core/Typography";
import { strpLocalize } from "../localization/localize";

const Closed = () => {
  return (
    <>
      <div>
        <Typography variant="h1" className="title">
          {strpLocalize("Whoops!")}
        </Typography>
        <h2 className="subtitle">
          {strpLocalize("This survey is no longer accepting responses.")}
        </h2>
      </div>
      <div style={{ marginTop: 30 }} />
    </>
  );
};

export default Closed;
