import React, { useEffect, useRef } from "react";
import { getVideoStream, relinquishStreamIfExists } from "../../utils/media";

/**
 * A circular webcam video. This component will request a user media and
 * initialize a video stream.
 *
 * @param {hide} - Option to temporarily hide the floating camera component.
 *  Use this option to avoid re-initialization of video stream
 */
const FloatingCam = ({ hide }) => {
  const floatingCamRef = useRef();
  const cameraStreamRef = useRef();

  const setCameraStream = (val) => (cameraStreamRef.current = val);
  const getCameraStream = () => cameraStreamRef.current;
  const getFloatingCam = () => floatingCamRef.current;
  const setFloatingCamStream = (stream) => {
    if (window.URL) getFloatingCam().srcObject = stream;
    else getFloatingCam().current.src = stream;
  };

  const setupWebCam = async () => {
    getVideoStream().then((stream) => {
      setCameraStream(stream);
      if (stream && getFloatingCam()) setFloatingCamStream(stream);
    });
  };

  useEffect(() => {
    setupWebCam();
    return () => relinquishStreamIfExists(getCameraStream());
  }, []);

  return (
    <div className="floating-cam">
      <video
        ref={floatingCamRef}
        autoPlay
        playsInline
        muted="muted"
        style={{
          width: "100%",
          display: hide ? "none" : "block",
        }}
      />
    </div>
  );
};

export default FloatingCam;
