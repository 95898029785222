import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";

const TextProgress = withStyles({
  root: {
    height: 5,
    marginBottom: -5,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#7fd296",
    },
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: 4,
  },
  under: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#999898",
    },
  },
  over: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#e48f8f",
    },
  },
}));

export default function ProgressBar(props) {
  const classes = useStyles();

  var value = 0;
  var status; // -1 is under (grey), 0 is within bounds (green), 1 is over (red)
  if (props.min && !props.max) {
    value = (100.0 * props.length) / props.min;
    status = value < 100 ? -1 : 0;
  } else if (!props.min && props.max) {
    value = (100.0 * props.length) / props.max;
    status = value > 100 ? 1 : 0;
  } else if (props.min && props.max) {
    // If both a min and max are specified, the first 50% of the progress bar fills until min is reached,
    // and the remaining 50% fills until the max is reached, at which point it indicates being over.
    if (props.length < props.min) {
      status = -1;
      value = (50.0 * props.length) / props.min;
    } else if (props.length > props.max) {
      status = 1;
      value = 100.0;
    } else {
      status = 0;
      value =
        50 + (50.0 * (props.length - props.min)) / (props.max - props.min);
    }
  }
  value = Math.min(value, 100.0);

  var cls = "";
  if (status == -1) cls = classes.under;
  else if (status == 1) cls = classes.over;

  return (
    <div className={clsx(classes.root, cls)}>
      <TextProgress variant="determinate" color="primary" value={value} />
    </div>
  );
}
