import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { strpLocalize } from "../../localization/localize";

const mapStateToProps = (state) => ({
  theme: state.survey.background,
  disableShortcuts: state.disableShortcuts,
  language: state.language,
});

const isMacOs = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  return macosPlatforms.test(userAgent);
};

export const ENTER_KEY = "Enter ⏎";
export const SHIFT_KEY = "Shift ⇧";
export const COMMAND_KEY = isMacOs() ? "Cmd ⌘" : "Ctrl";

const KeyboardShortcutInfo = (props) => {
  const beforeKeyPhrase = () => {
    switch (props.language) {
      case "ar":
      case "tr":
        return strpLocalize(`to ${props.action}`);
      case "ja":
        return "";
      default:
        return strpLocalize("Press");
    }
  };

  const afterKeyPhrase = () => {
    const action = strpLocalize(`to ${props.action}`);
    switch (props.language) {
      case "ar":
      case "tr":
        return strpLocalize("Press");
      case "ja":
        return strpLocalize("Press") + action;
      default:
        return action;
    }
  };

  return (
    !props.disableShortcuts && (
      <div className={clsx(props.theme, "key-shortcut-info")}>
        <span className="theme-color">{beforeKeyPhrase()}</span>{" "}
        {props.children} <span className="theme-color">{afterKeyPhrase()}</span>
      </div>
    )
  );
};

export default connect(mapStateToProps)(KeyboardShortcutInfo);
