import React, { Component } from "react";
import { fixedShuffleIndex, isMobileDevice } from "../../utils/utils";
import getDisplayableOptions from "../../utils/questionDisplayableOptions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { strpLocalize } from "../../localization/localize";

class Dropdown extends Component {
  state = { arr: [], options: [] };

  componentWillMount() {
    if (this.props.question.randomize) {
      var shuffledOptions = fixedShuffleIndex(
        this.props.question.options,
        this.props.question.fixed
      );
      this.setState({ options: shuffledOptions }, this.populateArr);
    } else {
      this.setState({ options: this.props.question.options }, this.populateArr);
    }
  }

  populateArr = () => {
    // Fill the select arr with false values, or populate from the saved state data arr if provided.
    var arr = new Array(this.props.question.options.length).fill(false);
    if (this.props.data) {
      for (let i = 0; i < arr.length; i++) {
        if (this.props.data[i]) {
          arr[
            this.getShuffledOptionsIndex(this.props.question.options[i])
          ] = true;
        }
      }
    }
    this.setState({
      arr: arr,
    });
  };

  getShuffledOptionsIndex = (option) => {
    return this.state.options.indexOf(option);
  };

  getRealOptionIndex = (option) => {
    return this.props.question.options.indexOf(option);
  };

  handleChange = (event) => {
    var displayArr = new Array(this.props.question.options.length).fill(false);
    var submitArr = new Array(this.props.question.options.length).fill(false);

    var realIndex = this.getRealOptionIndex(event.target.value);
    var shuffledIndex = this.getShuffledOptionsIndex(event.target.value);

    displayArr[shuffledIndex] = true;
    submitArr[realIndex] = true;

    this.props.onSubmit(submitArr);
    this.setState({ arr: displayArr });
  };

  getSelectedClass = (idx) => {
    return this.state.arr[idx] ? "selected" : "";
  };
  render() {
    const optionsDisplay = getDisplayableOptions(
      this.props.questionsState, 
      this.props.survey.questions, 
      this.props.question,
      this.props.preview
    );

    return (
      <Paper style={{ display: "inline-block" }}>
        <FormControl variant="outlined">
          {/* Use native selection on mobile */}
          {!isMobileDevice() ? (
            <Select
              onChange={this.handleChange.bind(this)}
              style={{ minWidth: 120, maxWidth: 250 }}
            >
              {this.state.options.map((option, idx) => {
                if(!optionsDisplay[idx]) return null;
                
                return (
                  <MenuItem key={`dropdown-${idx}`} value={option}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: strpLocalize(option),
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Select native onChange={this.handleChange.bind(this)}>
              {this.state.options.map((option, idx) => {
                if(!optionsDisplay[idx]) return null;
                
                return (
                  <option
                    key={`dropdown-${idx}`}
                    value={option}
                    dangerouslySetInnerHTML={{
                      __html: strpLocalize(option),
                    }}
                  />
                );
              })}
            </Select>
          )}
        </FormControl>
      </Paper>
    );
  }
}

export default Dropdown;
