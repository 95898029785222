import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardButton from "./KeyboardButton";
import KeyboardKey from "../Keyboard/KeyboardKey";
import { strpLocalize } from "../../localization/localize";
import KeyboardNavigation from "../Keyboard/KeyboardNavigation";
import KeyboardShortcutInfo, { COMMAND_KEY, SHIFT_KEY } from "../Keyboard/KeyboardShortcutInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    minWidth: 200,
    margin: "10px 0",
    fontSize: 20,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#E45049",
    color: "white",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FA544B",
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      minWidth: 150,
      fontSize: 18,
    },
  },
}));

const ShortcutTip = ({ shiftPress, cmdPress, recordPress, isRecording }) => {
  return (
    <KeyboardShortcutInfo action={isRecording?"stop":"record"}>
      <KeyboardKey pressed={shiftPress}>{SHIFT_KEY}</KeyboardKey>
      <KeyboardKey pressed={cmdPress}>{COMMAND_KEY}</KeyboardKey>
      <KeyboardKey pressed={recordPress}>R</KeyboardKey>
    </KeyboardShortcutInfo>
  );
};

const GeneralButton = React.forwardRef(
  ({ className, children, isRecording, ...other }, ref) => {
    const classes = useStyles();
    const buttonRef = ref || useRef(null);
    const [shiftPress, setShiftPress] = useState(false);
    const [cmdPress, setCmdPress] = useState(false);
    const [recordPress, setRecordPress] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const toggleRecord = () => {
      if (
        recordPress &&
        shiftPress &&
        buttonRef.current &&
        buttonRef.current.keyboardClick
      )
        buttonRef.current.keyboardClick(null, () => setRecordPress(false));
    };

    useEffect(() => {
      toggleRecord();
    }, [shiftPress, recordPress]);

    const tooltipProps = {
      title: (
        <ShortcutTip
          shiftPress={shiftPress}
          cmdPress={cmdPress}
          recordPress={recordPress}
          isRecording={isRecording}
        />
      ),
      placement: "left",
      open: isHovering || shiftPress,
    };

    return (
      <>
        <KeyboardButton
          className={clsx(classes.root, className, "record-button")}
          ref={buttonRef}
          aria-label={children}
          tooltipProps={tooltipProps}
          tooltipAriaLabel={strpLocalize("Record")}
          {...other}
          onMouseOver={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {children}
        </KeyboardButton>
        <KeyboardNavigation
          shiftDown={setShiftPress}
          cmdDown={setCmdPress}
          letterDown={(key) => setRecordPress(["r", "R"].includes(key))}
        />
      </>
    );
  }
);

export default GeneralButton;
