const colors = {
  tangerine: { dark: "#c57b57", light: "#fda085" },
  lavender: { dark: "#856084", light: "#a18cd1" },
  ocean: { dark: "#718abf", light: "#a1c4fd" },
  mint: { dark: "#0b8062", light: "#2ac79f" },
  turquoise: { dark: "#1f8b86", light: "#50c9c3" },
  passionfruit: { dark: "#ca3271", light: "#ff758c" },
  midnight: { dark: "#cecece", light: "#2b5876" },
  coffee: { dark: "#232529", light: "#878c8f" },
  lime: { dark: "#515455", light: "#96e6a1" },
  light: { dark: "#515455", light: "#616569" },
  dark: { dark: "#d1d1d1", light: "#8e8e8e" },
  white: { dark: "#000000", light: "#ffffff" },
  mars: { dark: " #040da4", light: "#00d7b8" },
};

export function getDarkColor(theme) {
  if (!(theme in colors)) return "#000000";
  return colors[theme].dark;
}

export function addHexTransparency(hex) {
  return `${hex}55`;
}
