import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FrameRecording from "./FrameRecording";
import {
  nextQuestion,
  nextStep,
  postResponse,
  prevStep,
  runLogicBeforeQuestion,
  showToast,
  skipToQuestionIdx,
  updateQuestionState,
  disableShortcuts,
  enableShortcuts,
} from "../redux/actions";
import { dataIsEqual } from "../utils/utils";
import {
  addStreamStopListener,
  getAudioLevel,
  getAudioStream,
  getScreenStream,
  relinquishStreamIfExists,
} from "../utils/media";
import { isLastQuestion, validateResponses } from "../utils/survey";
import { strpLocalize } from "../localization/localize";
import MissionStart from "./MissionStart";
import CountdownTimer from "../components/Progress/CountdownTimer";
import RecordRTC from "recordrtc";
import { trackPage } from "../analytics";
import StopScreenSharePopup from "../components/Mission/StopScreenSharePopup";
import PageVisibility from "react-page-visibility";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    questionState: state.questionState[state.questionIdx],
    questionIdx: state.questionIdx,
    sessionId: state.sessionId,
    question: state.question,
    preview: state.preview,
    audioRecordingFormat: state.audioRecordingFormat,
    pageQuery: state.conditions.page,
  };
};

const mapDispatchToProps = {
  postResponse,
  prevStep,
  nextStep,
  nextQuestion,
  skipToQuestionIdx,
  updateQuestionState,
  runLogicBeforeQuestion,
  showToast,
  disableShortcuts,
  enableShortcuts,
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     postResponse: (sId, qId, type, data, ms) =>
//       dispatch(postResponse(sId, qId, type, data, ms)),
//     prevStep: () => dispatch(prevStep()),
//     nextStep: () => dispatch(nextStep()),
//     nextQuestion: () => dispatch(nextQuestion()),
//     skipToQuestionIdx: (idx, popQStack) =>
//       dispatch(skipToQuestionIdx(idx, popQStack)),
//     updateQuestionState: (data) => dispatch(updateQuestionState(data)),
//     runLogicBeforeQuestion: () => dispatch(runLogicBeforeQuestion()),
//     showToast: (message) => dispatch(showToast(message)),
//   };
// };

const displayFrameStates = [
  "TUTORIAL",
  "CAPTURE",
  "COUNTDOWN",
  "RECORD",
  "QUESTION",
  "SUBMIT",
  "STOP",
  "HELP",
  "END_WARNING",
  "END_SUBMIT",
];
/** A component to render mission questions with a frame recording UI. A
 * mission is a state machine that displays UI based on the state the mission
 * is in
 *
 * A comprehensive list of all states:
 * 1. INTRODUCTION
 * 2. LOGIN
 * 3. TUTORIAL
 * 4. COUNTDOWN
 * 5. CAPTURE
 * 6. QUESTION
 * 7. SUBMIT
 * 8. STOP
 * 9. HELP
 * 10. END_WARNING
 * 11. END_SUBMIT
 */
class QuestionWithFrame extends Component {
  state = {
    frameState: null,
    recording: false,
    missionTour: true,
    connected: false,
    timerRunning: false,
    startTime: null,
    displayFrame: false,
    missionStep: 0,
    missionInfo:
      this.props.survey &&
      this.props.survey.questionGroups[this.props.question.groupId],
  };
  setFrameState = (value) => this.setState({ frameState: value });
  setRecording = (value) => this.setState({ recording: value });
  setMissionTour = (value) => this.setState({ missionTour: value });
  setConnected = (value) => this.setState({ connected: value });
  setTimerRunning = (value) => this.setState({ timerRunning: value });
  setStartTime = (value) => this.setState({ startTime: value });
  setDisplayFrame = (value) => this.setState({ displayFrame: value });

  skip = () => {
    let nextFunc = this.props.nextQuestion;
    if (isLastQuestion(this.props.questionIdx, this.props.survey))
      nextFunc = this.props.nextStep;

    if (!this.state.recording) {
      nextFunc();
    }
  };

  startTimer = () => {
    if (!this.state.timerRunning) {
      this.setStartTime(
        Date.now() -
          (this.props.questionState.responseMs
            ? this.props.questionState.responseMs
            : 0)
      );
      this.setTimerRunning(true);
    }
  };

  pauseTimer = () => {
    if (this.state.timerRunning) {
      const diff = Date.now() - this.state.startTime;
      this.props.updateQuestionState({ responseMs: diff });
      this.setTimerRunning(false);
    }
  };

  handleVisibility = (isVisible) => {
    if (isVisible) {
      this.startTimer();
    } else {
      this.pauseTimer();
    }
  };

  postResponseAndProceed = async () => {
    // Video & screen recording must be stopped manually.
    if (this.state.recording) {
      strpLocalize("Please stop recording first.");
      return;
    }

    const message = validateResponses(
      this.props.question,
      this.props.questionState
    );

    if (this.props.survey.state !== "DRAFT" && !this.props.preview && message)
      this.props.showToast(strpLocalize(message));

    // Check all other cases
    if (this.props.survey.state === "DRAFT" || this.props.preview || !message) {
      // This prevents questions from having to be re-submitted if the user goes back through the survey.
      if (
        !dataIsEqual(
          this.props.questionState.data,
          this.props.questionState.postedData
        ) ||
        this.props.questionState.requestState === "POST_ERROR" ||
        this.props.question.type === "DISPLAY"
      ) {
        // Wait for props.responseMs to update
        await this.pauseTimer();
        await this.props.postResponse(
          this.props.match.params.surveyId,
          this.props.question.questionId,
          this.props.question.type,
          this.props.questionState.data,
          this.props.questionState.responseMs
        );
      } else {
        this.skip();
      }
    }
  };

  componentWillMount() {
    trackPage("Question", {
      sessionId: this.props.sessionId,
      surveyId: this.props.match.params.surveyId,
    });
  }

  // Determines what frame state to load when QuestionWithFrame is first loaded
  getInitialState = () => {
    if (
      this.props.pageQuery !== undefined &&
      (this.props.survey.state === "DRAFT" || this.props.preview)
    ) {
      return "QUESTION";
    } else return "INTRODUCTION";
  };

  componentDidMount() {
    this.startTimer();

    this.calculateNumQuestions();

    // This runs only for BEFORE Q0
    this.props.runLogicBeforeQuestion();

    this.setFrameState(this.getInitialState());
  }

  releaseStreams() {
    relinquishStreamIfExists(this.stream);
    relinquishStreamIfExists(this.audioStream);
  }

  stopScreenShareCallback = () => {
    this.killRecording();
    this.setFrameState("STOP");
  };

  captureScreen = async () => {
    this.releaseStreams();
    this.stream = await getScreenStream();
    if (!this.stream) return;
    this.audioStream = await getAudioStream();
    if (this.audioStream) {
      this.audioLevel = getAudioLevel(this.audioStream);
      const audioTrack = this.audioStream.getTracks()[0];
      this.stream.addTrack(audioTrack);
    }
    // If the user clicks "stop sharing" we stop recording.
    addStreamStopListener(this.stream, this.stopScreenShareCallback);
    this.recorder = new RecordRTC(this.stream, {
      type: "video",
      mimeType: "video/webm",
    });
    this.setConnected(true);
  };

  startRecording = () => {
    if (this.props.question.type === "SCREEN") {
      this.recorder.startRecording();
      this.setRecording(true);
    }
  };

  saveScreenResponse = () => {
    if (this.props.question.type === "SCREEN") {
      let blob = this.recorder.getBlob();
      blob.name = "response_screen.webm";
      this.props.updateQuestionState({
        data: { ...this.props.questionState.data, SCREEN: blob },
      });
    }
  };

  killRecording = () => {
    if (this.state.recording) {
      this.recorder.stopRecording(() => {
        this.setRecording(false);
      });
    }
    this.releaseStreams();
    this.setConnected(false);
  };

  componentWillUnmount() {
    this.props.enableShortcuts();
    this.killRecording();
  }

  componentDidUpdate(prevProps, prevState) {
    // Mission changed
    if (prevProps.question.groupId !== this.props.question.groupId) {
      this.setState({
        missionInfo:
          this.props.survey &&
          this.props.survey.questionGroups[this.props.question.groupId],
      });
      this.setFrameState(this.getInitialState());
    }

    // Question changed
    if (prevProps.questionIdx !== this.props.questionIdx) {
      this.loadNewQuestion();
      this.setState({ missionStep: this.state.missionStep + 1 });
    }

    // FrameState changed
    if (prevState.frameState !== this.state.frameState) {
      this.handleStateUpdate(prevState.frameState);
    }

    // If new mission, set mission step to 0
    if (prevProps.question.groupId !== this.props.question.groupId) {
      this.setState({ missionStep: 0 });
    }
  }

  findMissionEndQuestionIdx() {
    let pastCurrentQuestion = false;
    let prevGroupId = undefined;
    for (let idx = 0; idx < this.props.survey.questions.length; idx++) {
      const question = this.props.survey.questions[idx];
      if (
        question.questionId === this.props.question.questionId &&
        question.groupId === this.props.question.groupId
      ) {
        pastCurrentQuestion = true;
      }
      if (pastCurrentQuestion && prevGroupId !== question.groupId) {
        return idx;
      }
      prevGroupId = question.groupId;
    }
    return this.props.survey.questions.length - 1;
  }

  calculateNumQuestions = () => {
    let totalMissionSteps = 0;
    let prevGroupId = undefined;
    for (let idx = 0; idx < this.props.survey.questions.length; idx++) {
      const question = this.props.survey.questions[idx];
      if (question.groupId === this.props.question.groupId)
        totalMissionSteps += 1;
    }
    this.setState({ totalMissionSteps: totalMissionSteps });
  };

  loadNewQuestion() {
    // Skip to next question
    if (this.state.frameState === "END_SUBMIT") {
      const questionIdx = this.findMissionEndQuestionIdx();
      this.props.skipToQuestionIdx(questionIdx);
    }

    // Run skip logic before question
    this.props.runLogicBeforeQuestion();

    // Start recording if screen question
    if (
      this.props.question.type === "SCREEN" &&
      this.props.pageQuery === undefined &&
      this.props.survey.state !== "DRAFT"
    ) {
      this.startRecording();
    }

    // Start response timer
    this.startTimer();
  }

  submitResponse = () => {
    if (this.state.recording) {
      this.recorder.stopRecording(() => {
        this.setState({ recording: false });
        if (
          this.props.questionState.requestState === "PENDING_INPUT" &&
          !this.props.preview &&
          this.props.survey.state !== "DRAFT"
        )
          this.saveScreenResponse();
        this.postResponseAndProceed();
      });
    } else {
      this.postResponseAndProceed();
    }
  };

  handleStateUpdate = (prevFrameState) => {
    if (this.state.frameState !== "INTRODUCTION") this.props.disableShortcuts();
    switch (this.state.frameState) {
      case "CAPTURE_OUT_FRAME":
      case "CAPTURE":
        this.captureScreen().then(() => {
          if (this.state.connected) this.setFrameState("COUNTDOWN");
          else this.setFrameState(prevFrameState);
        });
        break;
      case "RECORD":
        this.startRecording();
        this.setFrameState("QUESTION");
        break;
      case "SUBMIT":
        this.submitResponse();
        this.setFrameState("QUESTION");
        break;
      case "END_SUBMIT":
        this.submitResponse();
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <>
        <PageVisibility onChange={this.handleVisibility} />
        {this.state.frameState === "COUNTDOWN" && (
          <CountdownTimer
            seconds={3}
            onComplete={() => this.setFrameState("RECORD")}
          />
        )}
        {(this.state.frameState === "INTRODUCTION" ||
          this.state.frameState === "CAPTURE_OUT_FRAME") && (
          <MissionStart
            groupId={this.props.question.groupId}
            setMissionTour={this.setMissionTour}
            onStart={() => {
              if (this.state.missionTour) this.setFrameState("TUTORIAL");
              else this.setFrameState("CAPTURE_OUT_FRAME");
            }}
          />
        )}
        <StopScreenSharePopup
          open={this.state.frameState === "STOP"}
          handleClose={() => this.setFrameState("CAPTURE")}
        />
        {displayFrameStates.includes(this.state.frameState) && (
          <FrameRecording
            webcam={true}
            link={
              this.state.missionInfo.frameLink ||
              this.props.question.redirectLink
            }
            recorder={this.recorder}
            audioLevel={this.audioLevel}
            recording={this.state.recording}
            frameState={this.state.frameState}
            setFrameState={this.setFrameState}
            missionStep={this.state.missionStep}
            totalMissionSteps={this.state.totalMissionSteps}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionWithFrame)
);
