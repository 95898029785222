import React from "react";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";

const PermissionsWrapper = styled.div`
  background-color: rgba(221, 221, 221, 0.3);
  padding: 12px;
  margin: 0 16px 24px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  max-width: 320px;
  width: 100%;
`;

const SkeletonCircle = styled.div`
  flex: 0 0 auto;
  margin-right: 12px;
`;

const SkeletonText = styled.div`
  flex: 1 1 auto;
  > * {
    margin-bottom: 6px;
  }
`;

const Disallow = styled.span`
  padding: 6px 8px;
  cursor: default;
`;

const Allow = styled.span`
  padding: 6px 8px;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  cursor: default;
`;

const Permissions = () => {
  return (
    <PermissionsWrapper>
      <div className="flex items-center">
        <SkeletonCircle>
          <Skeleton animation={false} variant="circle" height={40} width={40} />
        </SkeletonCircle>
        <SkeletonText marginBottom="10">
          <Skeleton
            animation={false}
            variant="text"
            height={10}
            width="80%"
            style={{ marginBottom: 10 }}
          />
          <Skeleton animation={false} variant="text" height={8} />
          <Skeleton animation={false} variant="text" height={8} width="80%" />
          <Skeleton animation={false} variant="text" height={8} width="50%" />
        </SkeletonText>
      </div>
      <div
        className="flex justify-end"
        style={{ fontSize: 12, color: "white" }}
      >
        <Disallow>Don't Allow</Disallow>
        <Allow>Allow</Allow>
      </div>
    </PermissionsWrapper>
  );
};

export default Permissions;
