import * as RecordRTC from "recordrtc";
const MicRecorder = require("mic-recorder-to-mp3");

export class Recorder {
  constructor(format) {
    if (format === "wav") {
      this.recorder = new WAVRecorder();
    } else {
      // Default to MP3
      this.recorder = new MP3Recorder();
    }
  }

  start = () => {
    return this.recorder.start();
  };
  stop = () => {
    return this.recorder.stop();
  };
  getRecordingDuration = () => {
    return this.recorder.duration;
  };
}

export class MP3Recorder {
  constructor() {
    this.recorder = new MicRecorder({
      bitRate: 128,
    });
    this.startTime = null;
    this.duration = 0;
  }

  start = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .start()
        .then(() => {
          this.startTime = new Date().getTime();
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      this.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          this.duration = new Date().getTime() - this.startTime;
          const file = new File(buffer, "phonic.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });
          resolve(file);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}


function initWavRecorder(stream) {
  return RecordRTC(stream, {
    type: "audio", 
    mimeType: "audio/wav", 
    recorderType: RecordRTC.StereoAudioRecorder,
    numberOfAudioChannels: 2
  });
}

const MIN_WAV_SIZE = 44;

export class WAVRecorder {
  constructor() {
    this.startTime = null;
    this.duration = 0;
  }

  start = () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.recorder = initWavRecorder(stream.clone());
          this.recorder.onStateChanged = (state) => {
            if(state === 'recording') {
              this.startTime = new Date().getTime();
              resolve();
            };
          }
          this.recorder.startRecording();
        })
        .catch((e) => reject(e));
    });
  };

  stop = () => {
    return new Promise((resolve, reject) => {
      try {
        this.recorder.stopRecording(() => {
          this.duration = new Date().getTime() - this.startTime;

          let blob = this.recorder.getBlob();
          let file;
          
          // Checks for the minimun content for wav format
          if(blob.size > MIN_WAV_SIZE) {
            file = new File([blob], "phonic.wav", {
              type: blob.type,
              lastModified: Date.now(),
            });
          }

          resolve(file);
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}
