import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { strpLocalize } from "../../localization/localize";
import {isEmbed, postMessageToParent} from "../../utils/utils";
import ProgressBar from "../Progress/ProgressBar";
import ProgressBarWithPercentage from "../Progress/ProgressBarWithPercentage";
import { connect } from "react-redux";
import CountdownTimer from "../Progress/TruexCountdownTimer";

const mapStateToProps = (state) => {
    return {
        conditions: state.conditions,
    };
};

class Header extends Component {
  state = {
    countdownStarted: false,
  }

  getHeaderContent() {
    if (isEmbed()) return null;
    if (this.props.survey && this.props.survey.hideLogo) {
        if (this.props.conditions && this.props.conditions.truexSessionId) {
            return null
        }
        return <div style={{height: 50}}/>
    }

    let light = this.props.theme === "light";
    return (
      <>
        {this.props.img ? (
          <img
            className={"logo-navbar"}
            src={this.props.img}
            alt="powered by phonic"
          ></img>
        ) : isEmbed() ? null : (
          <a href="https://phonic.ai">
            <img
              className={"phonic-logo-navbar"}
              src={
                light
                  ? require("../../assets/img/powered-by-phonic-white.png")
                  : require("../../assets/img/powered-by-phonic-black.png")
              }
              alt="powered by phonic"
            ></img>
          </a>
        )}
      </>
    );
  }
  getBoxText() {
    if (isEmbed()) return null;
    if (this.props.survey && this.props.survey.state === "DRAFT")
      return (
        <div className="app-header-right">
          <div className="draft-text">{strpLocalize("DRAFT")}</div>
        </div>
      );
    else if (this.props.preview)
      return (
        <div className="app-header-right">
          <span className="preview-text">{strpLocalize("PREVIEW")}</span>
        </div>
      );
    return null;
  }

  getProgressBar() {
    if (this.props.survey) {
        if (this.props.survey.hideProgressBar) return null
        if (this.props.survey.showProgressBarPercentage) return (
            <ProgressBarWithPercentage value={this.props.progressValue}/>)
    }
    return (<ProgressBar value={this.props.progressValue} />)
  }

  getCountdownTimer() {
    if (this.props.conditions && this.props.conditions.truexSessionId && this.props.isFinish === true && !this.state.countdownStarted) {
      this.state.countdownTimer = true;
      return(
        <CountdownTimer
          seconds={5}
          onComplete={() => postMessageToParent("engagement::phonic_survey_end")}
        />
      )
    }
  }


  render() {
    return isEmbed() ? (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
            {this.getProgressBar()}
        </Grid>
      </Grid>
    ) : (
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <div className="app-header-left">{this.getHeaderContent()}</div>
        </Grid>
        <Grid container item xs={6} justifyContent="center" alignItems="center">
            {this.getProgressBar()}
        </Grid>
        <Grid item xs={3}>
          {this.getBoxText()}
          {this.getCountdownTimer()}
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapStateToProps)(Header);
