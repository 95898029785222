import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function EndMissionPopup({
  open,
  handleClose,
  handleEndMission,
  endButtonContent,
}) {
  const [reasonRecorded, setReasonRecorded] = useState(false);

  const resetAndHandleClose = () => {
    handleClose();
    setTimeout(() => setReasonRecorded(false), 1000);
  };

  return (
    <Dialog
      open={open}
      onClose={resetAndHandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {reasonRecorded ? "Confirm End Mission" : "End Mission Feedback"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {reasonRecorded
            ? "Ending Mission will stop screen share and skip all the followup tasks. You won't be able to come back to the mission."
            : "Please elaborate the reason to end the mission early before proceeding. Your audio is being recorded"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {reasonRecorded ? (
          <>
            <Button onClick={resetAndHandleClose} autoFocus>
              Resume Mission
            </Button>
            <Button
              onClick={handleEndMission}
              style={{
                backgroundColor: "#e45049",
                color: "white",
                minWidth: "100px",
              }}
            >
              {endButtonContent}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleClose} autoFocus>
              Back to mission
            </Button>
            <Button onClick={() => setReasonRecorded(true)}>
              I have explained the reason
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
