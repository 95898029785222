import React from "react";

import "../../assets/css/svg.css";

export default function Checkmark() {
  return (
    <svg
      className="status-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#73AF55"
        strokeWidth="12"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="56.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#73AF55"
        strokeWidth="12"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
}
