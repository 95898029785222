import React from "react";
import styled from "styled-components";
import Permissions from "./Permissions";

const DisconnectedWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const DisconnectedView = ({ message }) => (
  <DisconnectedWrapper>
    <div
      style={{
        backgroundColor: "rgba(35, 35, 35, 0.5)",
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Permissions />
    </div>
    {message}
  </DisconnectedWrapper>
);

export default DisconnectedView;
