class Stopwatch {
  constructor() {
    this.startTime = null;
  }
  get value() {
    return this.calcValue();
  }
  calcValue() {
    return this.startTime ? Date.now() - this.startTime : 0;
  }
  reset() {
    this.startTime = Date.now();
  }
}

export default Stopwatch;
