import { strpLocalize } from "../localization/localize";
const queryString = require("query-string");

export const steps = {
  WELCOME: 0,
  WARNING: 1,
  SUMMARY: 2,
  CONSENT: 3,
  MIC_CHECK: 4,
  PERSONAL_INFO: 5,
  QUESTION: 6,
  DEMOGRAPHICS: 7,
  FINISH: 8,
  CLOSED: -1,
  SCREEN_OUT: -2,
};

const shuffle = (a) => {
  return a.reduce((l, e, i) => {
    const j = Math.floor(Math.random() * (a.length - i) + i); // j is in [i, a.length]
    [a[i], a[j]] = [a[j], a[i]];
    return a;
  }, a);
};

// Shuffles an array while keeping certain elements fixed in their original positions.
// Parameters
// a: Source array to shuffle
// f: Boolean array indicating which elements should remain fixed (true = fixed)
export const fixedShuffleIndex = (a, f) => {
  var w = shuffle(
    a.reduce((acc, e, i) => {
      if (!f[i]) {
        acc.push(e);
      }
      return acc;
    }, [])
  );
  return f.reduce((acc, e, i) => {
    if (e) {
      acc.splice(i, 0, a[i]);
    }
    return acc;
  }, w);
};

export const secondsToString = (seconds) => {
  let clock =
    Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  // If there are no hours.
  if (clock.substring(0, 3) === "00:") return clock.slice(3);
  return clock;
};

export function isNumber(x) {
  return (
    ((typeof x === "number" ||
      x instanceof Number ||
      (x && typeof x === "string" && !isNaN(x))) &&
      isFinite(x)) ||
    false
  );
}

export const isValidDay = (numStr) => {
  if (typeof numStr !== "string") return false;

  const re = /\b(0[1-9]|[12][0-9]|3[01])\b/;
  // Add question mark for optional leading zero: /\b(0?[1-9]|[12][0-9]|3[01])\b/
  return re.test(numStr);
};

export const isValidMonth = (numStr) => {
  if (typeof numStr !== "string") return false;

  const re = /(^0[1-9]$)|(^1[0-2]$)/;
  // Add question mark for optional leading zero: /(^0?[1-9]$)|(^1[0-2]$)/
  return re.test(numStr);
};

export const showPersonalInfo = (survey) => {
  return (
    survey.demographics &&
    (survey.demographics.firstName ||
      survey.demographics.lastName ||
      survey.demographics.email)
  );
};

export const showDemographics = (survey) => {
  return (
    survey.demographics &&
    (survey.demographics.age ||
      survey.demographics.gender ||
      survey.demographics.hHIncome)
  );
};

export function convertYoutubeLinkToEmbedded(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  // match[2] is the 11 digit id from the end of the youtube clip.

  // Use this code if youtube-nocookie.com ever stops working. This proxy blocks the reccomended
  // videos at the end of a link since ?rel=0 no longer works.

  // return match && match[2].length === 11
  //   ? `https://www.youtube.com/embed/${match[2]}`
  //   : null;

  return match && match[2].length === 11
    ? `https://www.youtube-nocookie.com/embed/${match[2]}?modestbranding=1`
    : null;
}

export function convertGoogleDriveLinkToEmbedded(url) {
  return url.replace("/view", "/preview");
}

export function parseQueryString(qs) {
  return queryString.parse(qs);
}

export function stringifyQueryString(paramDict) {
  return queryString.stringify(paramDict);
}

export function addQueryParams(
  baseUrl,
  paramDict,
  existingParams /* optionally include current params */
) {
  paramDict = { ...parseQueryString(existingParams), ...paramDict };
  if (baseUrl.indexOf("?") > -1) {
    return baseUrl + "&" + queryString.stringify(paramDict);
  } else {
    return baseUrl + "?" + queryString.stringify(paramDict);
  }
}

export function getVideoFileExtension(fname) {
  if (!fname || !fname.includes(".")) return ".webm"; // Default video records webm
  return fname.split(".").pop().trim();
}

export function dataIsEqual(d1, d2) {
  if (!d1 || !d2) return false;
  for (const key in d1) {
    if (!(key in d2)) return false;
    if (key === "VIDEO") {
      return d1["VIDEO"].size === d2["VIDEO"].size;
    } else {
      // Force a re-upload on everything else, just to be safe.
      return false;
    }
  }
  return true;
}

export function getContrastYIQ(hexcolor) {
  if (!hexcolor) return "black";

  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 100 ? "black" : "white";
}

/* eslint-disable no-useless-escape */
export function isValidEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function isMobileDevice() {
  let isMobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      isMobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return isMobile;
}

export const getDeviceType = () => {
  const a = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    )
  ) {
    return "mobile";
  } else if (
    /android|ipad|playbook|silk/i.test(a) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "tablet";
  }
  return "desktop";
};

export function getBrowserInfo() {
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix, v;
  var recordingSupported = true;

  // Internet Explorer - not compatible
  if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
    recordingSupported = false;
  }
  // Edge --> v >= 79.0
  else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 5);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
    v = fullVersion.split(" ")[0];

    if (parseFloat(v) >= 79) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }
  // Firefox --> v >=29.0
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
    v = fullVersion.split(" ")[0];
    if (parseFloat(v) >= 29.0) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }
  // Chrome --> v >= 49
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
    v = fullVersion.split(" ")[0];
    if (parseFloat(v) >= 49) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }
  // Safari --> v >= 14.1
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);

    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
    v = fullVersion.split(" ")[0];

    if (parseFloat(v) >= 14.1) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }
  // Opera --> v >= 36
  else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
    v = fullVersion.split(" ")[0];
    if (parseFloat(v) >= 36) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }

  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    v = fullVersion.split(" ")[0];
    if (parseFloat(v) >= 29) {
      recordingSupported = true;
    } else {
      recordingSupported = false;
    }
  }

  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    name: browserName,
    version: fullVersion,
    recordingSupported: recordingSupported,
  };
}

export function showScreenRecordingWarning(survey) {
  if (!isMobileDevice()) return false;

  if (!survey || !survey.questions) return false;
  for (const q in survey.questions) {
    if (survey.questions[q].type === "SCREEN") return true;
  }
  return false;
}

export function formatUrl(url) {
  var httpString = "http://";
  var httpsString = "https://";
  if (
    url.substr(0, httpString.length).toLowerCase() !== httpString &&
    url.substr(0, httpsString.length).toLowerCase() !== httpsString
  )
    url = httpString + url;
  return url;
}

export function replaceLineBreaksHtml(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

// Maps a short code like "en" to a default language + dialect code like "en-US".
export function mapShortCodeToDialect(shortCode) {
  if (shortCode.includes("-")) return shortCode; // The code is already full.
  const langCodeMap = {
    en: "en-US",
    ar: "ar-SA",
    zh: "zh-CN",
    nl: "nl-NL",
    es: "es-ES",
    fr: "fr-FR",
    fa: "fa-IR",
    de: "de-DE",
    he: "he-IL",
    hi: "hi-IN",
    id: "id-ID",
    it: "it-IT",
    ja: "ja-JP",
    ko: "ko-KR",
    ma: "ms-MY",
    pt: "pt-PT",
    ru: "ru-RU",
    ta: "ta-IN",
    te: "te-IN",
    tr: "tr-TR",
    vi: "vi-VN"
  };

  if (shortCode in langCodeMap) return langCodeMap[shortCode];
  return shortCode; // The code is either full or unsupported.
}

export function convertLangCodeToISO639(code) {
  if (!code) return "en"; // Default to English.

  if (code === "gsw") return "de"; // There is no Swiss German ISO-639
  if (code === "dut") return "nl";
  if (code.includes("-")) return code.substring(0, 2);
  return code;
}

export function convertTranslationsSchema(translations) {
  // Converts [{text: "Hello", fr: "Bonjour"},...] => {"Hello": {fr: "Bonjour"}}
  let x = Object.assign({}, ...translations.map((t) => ({ [t.text]: t })));
  return x;
}

export const humanTime = (sec) => {
  const minutes = Math.floor(sec / 60);
  const seconds = sec - minutes * 60;

  let time = "";

  if (minutes) {
    time = `${minutes} ${strpLocalize("min")}`;
  }

  if (minutes && seconds) {
    time += ` ${seconds} ${strpLocalize("s")}`;
  } else if (seconds) {
    time += `${seconds} ${strpLocalize("s")}`;
  }

  return time;
};

export const isEmbed = () => {
  const queryParams = parseQueryString(document.location.search);
  if (queryParams.embed === "true") return true;
};

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getAlignValue(align, context) {
  if (context === "justifyContent") {
    return align === "left"
      ? "flex-start"
      : align === "right"
      ? "flex-end"
      : "center";
  }

  return null;
}

// Extracts the <script> tags from a string
export function parseScriptsFromString(str) {
  var doc = document.implementation.createHTMLDocument(); // Acts as a sandbox
  doc.body.innerHTML = str;
  var scripts = Array.prototype.map.call(
    doc.getElementsByTagName("script"),
    function (el) {
      return { src: el.src, text: el.textContent };
    }
  );
  return scripts;
}

export function postMessageToParent(message, targetOrigin = '*') {
  window.parent.postMessage(
      message,
      targetOrigin
  );
}