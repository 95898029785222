import React from "react";
import styled from "styled-components";

const LoadingMessage = styled.div`
  color: #333333;
`;

const LoadingView = () => <LoadingMessage>Loading...</LoadingMessage>;

export default LoadingView;
