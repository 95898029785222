// This transformer keys the state persisted based on survey URL
// so that the user can have multiple simultaneous surveys on the go
// that would otherwise conflict.
import { createTransform } from "redux-persist";
import _ from "lodash";

const QUESTION_BLACKLIST = ["isRecording", "requestProgress"];
const QUESTION_DATA_BLACKLIST = ["AUDIO", "VIDEO", "SCREEN", "FILE"];

const QuestionStateTransformer = createTransform(
  // Transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // inboundState is a pointer to the Redux state itself. Be careful with modifying it directly.
    let inboundStateCopy = _.cloneDeep(inboundState);
    if (key === "questionState") {
      if (inboundState) {
        // Need to iterate through keys since questionState is a dictionary
        Object.keys(inboundState).forEach((k) => {
          let qState = inboundStateCopy[k];
          // Blacklisting on questionState fields
          QUESTION_BLACKLIST.forEach((field) => {
            if (field in qState) qState[field] = undefined;
          });
          // Blacklisting on questionState data fields
          QUESTION_DATA_BLACKLIST.forEach((field) => {
            if (field in qState.data) qState.data[field] = undefined;
          });
          // Resetting partial uploads
          if (qState.requestState === "POST_ATTEMPTED")
            qState.requestState = "PENDING_INPUT";
          inboundStateCopy[k] = qState;
        });
      }
    }
    return inboundStateCopy;
  },
  // Transform state being rehydrated.
  (outboundState, key) => {
    return outboundState;
  },
  // define which reducers this transform gets called for.
  {
    whitelist: [
      "survey",
      "questionState",
      "question",
      "questionStack",
      "questionIdx",
      "demographics",
      "sessionMs",
      "requestStates",
      "conditions",
      "sessionId",
      "step",
      "language",
      "preview",
    ],
  }
);

export default QuestionStateTransformer;
