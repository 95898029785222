import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ededed",
    height: 45,
    minWidth: 145,
    padding: "0 20px",
    margin: "10px 0 10px 8px",
    fontSize: 18,
    backgroundColor: "#6b6b6b",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      background: "#919191",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 8,
      height: 40,
      fontSize: 16,
      padding: "0 10px",
      minWidth: 100,
    },
  },
}));

const GeneralButton = ({ className, children, ...other }) => {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.root, className)} {...other} aria-label={children}>
      {children}
    </Button>
  );
};

export default GeneralButton;
