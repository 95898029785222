import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";

const DisconnectedWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;

  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const PermissionsWrapper = styled.div`
  background-color: rgba(221, 221, 221, 0.3);
  height: 137px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const PleaseAllow = styled.div`
  height: 90px;
  padding: 0 12px;
  color: #333333;
  display: flex;
  align-items: center;
`;

const ErrorView = () => (
  <DisconnectedWrapper>
    <div
      style={{
        backgroundColor: "rgba(35, 35, 35, 0.5)",
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
      }}
    >
      <PermissionsWrapper>
        <FontAwesomeIcon icon={faVideoSlash} />
      </PermissionsWrapper>
    </div>
    <PleaseAllow>
      <span>
        Please allow access to your camera and microphone to record video. Try
        again and select <b>Allow</b>.
      </span>
    </PleaseAllow>
  </DisconnectedWrapper>
);

export default ErrorView;
