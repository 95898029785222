import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";

export default function CountdownTimer({ seconds, onComplete }) {
    const [timer, setTimer] = React.useState(seconds);

    useEffect(() => {
        if (timer === 0) {
            onComplete();
        } else {
            setTimeout(() => setTimer(timer - 1), 1000);
        }
    }, [timer, onComplete]);

    return (
        <div className="countdown-truex">
            <Typography variant="body2" className="title">RETURNING TO CONTENT IN: {timer}</Typography>
        </div>
    );
}
