import React, { useEffect } from "react";

const KeyboardNavigation = ({
  shiftDown,
  cmdDown,
  enterDown,
  letterDown,
  arrowDown,
  playDown,
  cameraDown,
}) => {
  const shiftCmdPressed = (e) => e.shiftKey && (e.ctrlKey || e.metaKey);
  const isLetter = (e) =>
    e.key.length === 1 &&
    ((e.key >= "a" && e.key <= "z") || (e.key >= "A" && e.key <= "Z"));

  const isArrowPressed = (e) =>
    ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.key);

  useEffect(() => {
    function handleKeyDown(e) {
      if (!e.repeat) {
        if (e.shiftKey && shiftDown) shiftDown(true);
        if ((e.ctrlKey || e.metaKey) && cmdDown) cmdDown(true);
        if (e.key === "Enter" && enterDown) enterDown(true);
        if (isArrowPressed(e) && arrowDown) arrowDown(e);
        if (isLetter(e) && letterDown) letterDown(e.key);
        if (shiftCmdPressed(e) && ["p", "P"].includes(e.key) && playDown)
          playDown(e);
        if (e.shiftKey && ["c", "C"].includes(e.key) && playDown) cameraDown();
      }
      if (shiftCmdPressed(e) && ["B", "b", "R", "r"].includes(e.key)) {
        e.preventDefault();
      }
    }

    function handleKeyUp(e) {
      if (!e.repeat) {
        if (!e.shiftKey && shiftDown) shiftDown(false);
        if (!e.ctrlKey && !e.metaKey && cmdDown) cmdDown(false);
        if (e.key !== "Enter" && enterDown) enterDown(false);
        if (letterDown) letterDown(false);
        if (arrowDown) arrowDown(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return <></>;
};

export default KeyboardNavigation;
