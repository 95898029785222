import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import VideoRecordButton from "./VideoRecordButton";
import Timer from "./Timer";
import Countdown from "./Countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faSquare } from "@fortawesome/free-solid-svg-icons";
import { strpLocalize } from "../../localization/localize";
import Tooltip from "@material-ui/core/Tooltip";

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  useNativeCamera,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,
  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStopReplaying,
  onConfirm,
}) => {
  const renderContent = () => {
    const shouldUseVideoInput = useNativeCamera
      ? isVideoInputSupported
      : !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <div className="another-video-button">
          <Button
            onClick={onStopReplaying}
            data-qa="start-replaying"
            style={{ marginLeft: 10 }}
          >
            {strpLocalize("Use Another Video")}
          </Button>
        </div>
      );
    }

    if (isRecording) {
      return (
        <div className="video-overlay-button">
          <Tooltip
            title={strpLocalize("Stop Recording")}
            aria-label={strpLocalize("Stop Recording")}
            placement="top"
          >
            <VideoRecordButton
              onClick={onStopRecording}
              isRecording
              data-qa="stop-recording"
            >
              <FontAwesomeIcon icon={faSquare} />
            </VideoRecordButton>
          </Tooltip>
        </div>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <div className="video-overlay-button">
          <span className="video-helper-text">
            <FontAwesomeIcon icon={faVideo} className="mr-1" />
            {strpLocalize("Record")}
          </span>
          <br />
          <VideoRecordButton
            onClick={onStartRecording}
            data-qa="start-recording"
          >
            {" "}
          </VideoRecordButton>
        </div>
      );
    }

    if (useVideoInput) {
      return (
        <div className="video-overlay-button">
          <Button onClick={onOpenVideoInput} data-qa="open-input">
            {strpLocalize("Upload A Video")}
          </Button>
        </div>
      );
    }

    return shouldUseVideoInput ? (
      <div className="camera-on-button">
        <Button onClick={onOpenVideoInput} data-qa="open-input">
          {strpLocalize("Record A Video")}
        </Button>
      </div>
    ) : (
      <div className="camera-on-button">
        <Button onClick={onTurnOnCamera} data-qa="turn-on-camera">
          {thereWasAnError ? "Try Again" : strpLocalize("Turn Camera On")}
        </Button>
      </div>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      {renderContent()}
    </div>
  );
};

Actions.propTypes = {
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  replayVideoAutoplayAndLoopOff: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,
  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Actions;
