import React, { useEffect, useRef, useState } from "react";
import Stopwatch from "../Progress/Stopwatch";

const Reminder = (props) => {
  return (
    <div
      className="reminder"
      style={{
        opacity: props.show ? "1" : "0",
        visibility: props.show ? "visible" : "hidden",
        right: props.show ? "190px" : "50px",
      }}
    >
      {props.children}
    </div>
  );
};

const AudioAnimation = ({ audioLevel, animation, className }) => {
  const [showAlert, setShowAlert] = useState(false);
  const quietWatch = new Stopwatch();
  const loudWatch = new Stopwatch();
  const canvasAnimation = useRef();
  const drawAnimation = useRef();

  const drawShape = (canvas) => {
    const context = canvas.getContext("2d");
    canvas.height = 100;
    canvas.width = 100;
    const canvasHeight = canvas.height;
    const canvasWidth = canvas.width;

    return function (level) {
      const maxRadius = animation.circle.maxRadius || 50;
      const minRadius = animation.circle.minRadius || 0;
      var radius =
        minRadius + Math.round((maxRadius - minRadius) * level * 25) / 5;

      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.beginPath();
      context.arc(
        canvasWidth / 2,
        canvasHeight / 2,
        radius,
        0,
        Math.PI * 2,
        false
      );
      context.lineWidth = animation.circle.borderWidth || 1;
      context.strokeStyle =
        animation.circle.borderColor || "rgba(90, 210, 255, 0.5)";
      context.stroke();
      context.fillStyle =
        animation.circle.fillColor || "rgba(72, 198, 239, 0.5)";
      context.fill();
    };
  };

  const audioAnimation = () => {
    if (audioLevel) {
      const level = Math.round(audioLevel.get() * 300);
      if (level >= 15) {
        if (loudWatch.value >= 200) {
          quietWatch.reset();
          setShowAlert(false);
        }
      } else {
        if (quietWatch.value >= 5000) setShowAlert(true);
        loudWatch.reset();
      }
    }

    drawAnimation.current(audioLevel.get());
    requestAnimationFrame(audioAnimation);
  };

  useEffect(() => {
    quietWatch.reset();
    drawAnimation.current = drawShape(canvasAnimation.current);
    requestAnimationFrame(audioAnimation);
    return () => {
      cancelAnimationFrame(audioAnimation);
    };
  }, [audioAnimation, drawShape, quietWatch]);

  return (
    <>
      <div className={className}>
        <canvas
          ref={canvasAnimation}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <Reminder show={showAlert}>Reminder to Think Aloud</Reminder>)
    </>
  );
};

export default AudioAnimation;
