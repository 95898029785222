import React, { createRef } from "react";
import Button from "@material-ui/core/Button";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import TransparentTooltip from "../Help/TransparentTooltip";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  disableShortcuts: state.disableShortcuts,
});

class KeyboardButton extends React.Component {
  rippleRef = createRef();

  keyboardClick = (event, callback) => {
    if (this.props.disableShortcuts) return;

    if (this.rippleRef.current)
      // Ripple doesn't travel to the border of the container when center true
      this.rippleRef.current.start(
        { clientX: 0, clientY: 0 },
        { center: false }
      );

    setTimeout(() => {
      if (this.rippleRef.current) this.rippleRef.current.stop({});
      this.props.onClick(event);
      if (callback) callback();
    }, 0);
  };

  render() {
    const {
      className,
      children,
      tooltipProps,
      disableShortcuts,
      tooltipAriaLabel,
      dispatch,
      ...other
    } = this.props;
    return (
      <TransparentTooltip {...tooltipProps} aria-label={tooltipAriaLabel}>
        <Button className={className} {...other} aria-label={children}>
          {children}
          <TouchRipple ref={this.rippleRef} center />
        </Button>
      </TransparentTooltip>
    );
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  KeyboardButton
);
