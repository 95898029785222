import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import Main from "./views/Main";
import Help from "./views/Help";
import Home from "./views/Home";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";

Sentry.init({
  dsn: "https://1c28558d42745164d7909778ef1c0413@o4507692303056896.ingest.us.sentry.io/4507692323045376",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ["error", "warn"],
    }),
  ],
  tracesSampleRate: 1.0,
});

window.PhonicProps = {};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/help">
        <Help />
      </Route>
      <Route exact path="/:surveyId">
        <PersistGate loading={null} persistor={persistor}>
          <Provider store={store}>
            <Main />
          </Provider>
        </PersistGate>
      </Route>
      <Route path="">
        <Home />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
