import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    height: 48,
    padding: "0 10px",
    marginRight: 20,
    marginLeft: 20,
    minWidth: 120,
    fontFamily: "Roboto, sans-serif",
    fontSize: 18,
    textTransform: "none",
    fontStyle: "normal",
  },
};

function ContinueButton(props) {
  const { classes, children, className, ...other } = props;

  return (
    <Button
      className={clsx(classes.root, className, "continue-button")}
      {...other}
      aria-label={children}
    >
      {children}
    </Button>
  );
}

ContinueButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(ContinueButton);
