import React from "react";
import clsx from "clsx";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import TransparentTooltip from "../Help/TransparentTooltip";
import KeyboardNavigation from "../Keyboard/KeyboardNavigation";
import KeyboardKey from "../Keyboard/KeyboardKey";
import { strpLocalize } from "../../localization/localize";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    width: 64,
    margin: "10px 0",
    fontSize: 30,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: (props) => (props.isRecording ? "white" : "#E45049"),
    color: "#E45049",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: (props) => (props.isRecording ? {} : "#FA544B"),
    },
    [theme.breakpoints.down("xs")]: {
      height: 36,
      width: 36,
      fontSize: 18,
    },
  },
}));

const GeneralButton = React.forwardRef(
  ({ className, children, isRecording, ...other }, ref) => {
    const classes = useStyles({ isRecording });

    return (
      <>
        <TransparentTooltip
          title={
            <div className={other.theme}>
              <span className="theme-color">{strpLocalize("Press")} </span>
              <KeyboardKey>R</KeyboardKey>
              <span className="theme-color">
                {" "}
                {isRecording
                  ? strpLocalize("to stop recording")
                  : strpLocalize("to record")}
              </span>
            </div>
          }
          aria-label={strpLocalize("Record")}
          placement="bottom"
        >
          <Fab
            className={clsx(classes.root, className, "record-button")}
            {...other}
            ref={ref}
          >
            {children}
          </Fab>
        </TransparentTooltip>
        <KeyboardNavigation recordDown={other.onClick} />
      </>
    );
  }
);

export default GeneralButton;
