var AWS = require("aws-sdk");

const BUCKET_NAME = "tmp746642-us-west-2";

AWS.config.region = "us-west-2"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "us-west-2:1af70691-77c2-4801-82b4-b56696cd5b17",
});
var s3 = new AWS.S3({
  params: { Bucket: BUCKET_NAME },
  useAccelerateEndpoint: true,
});

export function singlePartUpload(file, path) {
  return s3.upload(
    {
      Key: path,
      Body: file,
      ACL: "public-read",
    },
    function (err, data) {
      if (err) {
        throw Error(err);
      }
    }
  );
}
