import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Header from "../components/Header/Header";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import MenuBookIcon from "@material-ui/icons/MenuBook";

class Home extends Component {
  onSubmit = (e) => {
    e.preventDefault();
    if (!this.surveyUrl) return;
    if (
      this.surveyUrl.startsWith("http://survey.phonic.ai") ||
      this.surveyUrl.startsWith("https://survey.phonic.ai") ||
      this.surveyUrl.startsWith("survey.phonic.ai")
    ) {
      window.location.href = this.surveyUrl;
    } else {
      window.location.href = `https://survey.phonic.ai/${this.surveyUrl}`;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>PHONIC</title>
        </Helmet>
        <div className="Home">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Header />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={10} md={6} lg={4}>
              <Paper className="home-paper">
                <h2>Find A Survey</h2>
                <p>Enter a survey ID or URL to be be redirected there.</p>
                <form onSubmit={this.onSubmit}>
                  <TextField
                    id="survey-search"
                    label="Survey ID or URL"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: 12 }}
                    onChange={(e) => {
                      this.surveyUrl = e.target.value;
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    endIcon={<SendIcon />}
                    type="submit"
                  >
                    Go
                  </Button>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={10} md={6} lg={4}>
              <Paper className="home-paper">
                <h2>Phonic Updates</h2>
                <p>Get the latest product and R&D updates.</p>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  endIcon={<MenuBookIcon />}
                  href="https://phonic.ai/blog"
                >
                  Visit Our Blog
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withRouter(Home);
