/*
ConfirmationPopup is a reusable popup dialog for any action needing user confirmation.
*/
import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { strpLocalize } from "../../localization/localize";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ConfirmationPopup extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        fullWidth
        onClose={this.props.cancel}
        aria-describedby="continue-popup-dialog"
      >
        <DialogTitle>
          {strpLocalize(this.props.title)}
          <IconButton
            size="small"
            edge="end"
            style={{ float: "right" }}
            onClick={this.props.cancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              {strpLocalize(this.props.body)}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.cancel}>Cancel</Button>
          <Button onClick={this.props.confirm}>Continue</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
