import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Sorry from "../assets/img/sorry.png";
import { strpLocalize } from "../localization/localize";

class NotFound extends Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="not-found-container"
      >
        <Grid item xs={12} sm={8} md={6}>
          <div className="not-found">
            <img src={Sorry} alt="Page not found" />
            <h2>{strpLocalize("We can't find what you're looking for.")}</h2>
            <p>{strpLocalize("Are you sure you have the right URL?")}</p>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default NotFound;
