import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import reducer from "./reducer";
import QuestionStateTransformer from "./QuestionStateTransformer";

const persistConfig = {
  key: window.location.pathname,
  storage: storage,
  stateReconciler: hardSet,
  blacklist: ["preview"],
  transforms: [QuestionStateTransformer],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const initialState = {
  questionState: {},
  questionIdx: 0,
  questionStack: [],
  step: 0,
  demographics: {},
  conditions: {},
  sessionMs: 0,
  requestStates: {
    Demographics: "PENDING_INPUT",
  } /* requestState is currently used for demographics. Question request state is stored within questionState. */,
};

const store = createStore(
  persistedReducer,
  initialState,
  applyMiddleware(thunk)
);
window.store = store;

let persistor = persistStore(store);
persistor.pause(); // Persisting off by default (survey enables this).
window.persistor = persistor;

export { store, persistor };
