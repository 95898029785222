import React, { Component } from "react";

import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import PageVisibility from "react-page-visibility";
import Header from "../components/Header/Header";
import ContinuePopup from "../components/ContinuePopup";
import HelpText from "../components/Help/HelpText";
import GlobalToast from "../components/Alerts/GlobalToast";
import HelpPopup from "../components/Popups/HelpPopup";
import Welcome from "./Welcome";
import Warning from "./Warning";
import Finished from "./Finished";
import Summary from "./Summary";
import Consent from "./Consent";
import MicCheck from "./MicCheck";
import Demographics from "./Demographics";
import PersonalInfo from "./PersonalInfo";
import Loading from "./Loading";
import NotFound from "./NotFound";
import Closed from "./Closed";
import ScreenOut from "./ScreenOut";
import { connect } from "react-redux";
import {
  getSurvey,
  generateSessionId,
  nextStep,
  prevStep,
  postDemographics,
  isPreview,
  updateLanguage,
  updateCondition,
  setSessionTime,
  submitFeedback,
  disableShortcuts,
  enableShortcuts,
} from "../redux/actions";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import {
  steps,
  showDemographics,
  parseQueryString,
  getContrastYIQ,
  showScreenRecordingWarning,
  showPersonalInfo,
  convertLangCodeToISO639,
  parseScriptsFromString,
  postMessageToParent
} from "../utils/utils";

import { persistor } from "../redux/store";
import { dispatchEventSurveyStarted } from "../events";
import { localize } from "../localization/localize";
import NoSleep from "nosleep.js";
import { getTheme } from "../themes";
import QuestionView from "./QuestionView";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    preview: state.preview,
    questionIdx: state.questionIdx,
    conditions: state.conditions,
    surveyError: state.surveyError,
    step: state.step,
    sessionId: state.sessionId,
    language: state.language,
    audioRecordingFormat: state.audioRecordingFormat,
    sessionMs: state.sessionMs,
    feedback: state.feedback,
  };
};

const mapDispatchToProps = {
  getSurvey,
  generateSessionId,
  nextStep,
  prevStep,
  postDemographics,
  isPreview,
  updateLanguage,
  updateCondition,
  setSessionTime,
  submitFeedback,
  disableShortcuts,
  enableShortcuts,
};

class Main extends Component {
  state = {
    continuePopupOpen: false,
    startTime: null,
    timerRunning: false,
    helpPopupOpen: false,
  };

  componentWillMount() {
    document.addEventListener('click', this.handleClick);

    if (!this.props.sessionId) this.props.generateSessionId();

    var queryParams = parseQueryString(this.props.location.search);
    this.noSleep = new NoSleep();

    for (let q in queryParams)
      this.props.updateCondition({ [q]: queryParams[q] });

    if (queryParams.preview) this.props.isPreview();
    if (queryParams.lang) {
      this.props.updateLanguage(queryParams.lang);
      localize.setLocale(queryParams.lang);
    }

    // If there is a persisted survey in state, don't load a new one.
    // TODO: Check if any data has been collected in questionState
    if (
      !this.props.survey ||
      this.props.step === steps.WELCOME ||
      this.props.preview
    ) {
      this.props.getSurvey(this.props.match.params.surveyId, {
        staging: queryParams.staging,
        static: queryParams.page && queryParams.preview === "true",
      });
      this.props.enableShortcuts();
    } else {
      persistor.persist();
      this.props.disableShortcuts();
      this.setState({ continuePopupOpen: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.step !== this.props.step &&
      this.props.step > steps.MIC_CHECK
    ) {
      this.startTimer();
    }
  }

  clickToBegin = () => {
    this.noSleep.enable();
    this.props.nextStep();
    dispatchEventSurveyStarted();
  };

  getProgressPercent() {
    if (this.props.step === steps.CLOSED) {
      return 100;
    }

    if (!this.props.survey) return 0;

    let numerator = this.props.step;
    let denominator = steps.FINISH;

    // Optional screens only contribute to progress bar if they are shown.
    if (this.props.survey.removeWelcomeScreen) {
      denominator--;
      numerator--;
    }
    if (!showScreenRecordingWarning(this.props.survey)) {
      denominator--;
      if (this.props.step > steps.WARNING) numerator--;
    }
    if (!this.props.survey.summary) {
      denominator--;
      if (this.props.step > steps.SUMMARY) numerator--;
    }
    if (!this.props.survey.consentPDFUri) {
      denominator--;
      if (this.props.step > steps.CONSENT) numerator--;
    }
    if (!this.props.survey.includeMicCheck) {
      denominator--;
      if (this.props.step > steps.MIC_CHECK) numerator--;
    }
    if (!showPersonalInfo(this.props.survey)) {
      denominator--;
      if (this.props.step > steps.PERSONAL_INFO) numerator--;
    }
    if (!showDemographics(this.props.survey)) {
      denominator--;
      if (this.props.step > steps.DEMOGRAPHICS) numerator--;
    }

    // Questions progress bar component.
    if (this.props.survey.questions) {
      denominator += this.props.survey.questions.length;
      if (this.props.step === steps.QUESTION) {
        numerator += this.props.questionIdx;
      } else if (this.props.step > steps.QUESTION) {
        numerator += this.props.survey.questions.length;
      }
    }

    return (100.0 * numerator) / denominator;
  }

  // Returns "light" or "dark" depending the background
  getHeaderFontColor() {
    if (!this.props.survey) return null;
    if (this.props.survey.background === "custom") {
      return getContrastYIQ(this.props.survey.customBackground) === "white"
        ? "light"
        : "dark";
    } else {
      let LIGHT_THEMES = ["light", "coffee", "ocean"];
      // Only the light theme should use dark font color, everything else uses white.
      if (LIGHT_THEMES.includes(this.props.survey.background)) return "dark";
      return "light";
    }
  }

  getThemeClass() {
    if (!this.props.survey) return null;
    return this.props.survey.background === "custom"
      ? getContrastYIQ(this.props.survey.customBackground) === "white"
        ? "dark custom"
        : "light custom"
      : this.props.survey.background;
  }
  getFontClass() {
    if (!this.props.survey) return null;
    return this.props.survey.font;
  }
  getAlignment() {
    if (!this.props.survey || this.props.survey.align === "center") {
      return null;
    }
    return this.props.survey.align;
  }
  getBackgroundStyle() {
    if (!this.props.survey) return {};
    // Case 1: All custom color
    if (this.props.survey.background === "custom") {
      return {
        background: this.props.survey.customBackground,
      };
    }
    // Case 2: All custom image
    else if (this.props.survey.background === "customImage") {
      return {
        backgroundImage: `url(${this.props.survey.customBackgroundImage})`,
      };
    }
    // Case 3: Custom welcome image, then color color
    else if (this.props.survey.welcomeBackgroundImageUrl) {
      if (this.props.step === steps.WELCOME) {
        return {
          backgroundImage: `url(${this.props.survey.welcomeBackgroundImageUrl})`,
        };
      } else {
        return {
          background: this.props.survey.customBackground,
        };
      }
    }
  }
  handleContinuePopupClose = () => {
    this.props.enableShortcuts();
    this.setState({ continuePopupOpen: false });
  };
  restartSurvey = () => {
    persistor.purge().then(() => {
      this.setState({ continuePopupOpen: false });
      window.location.reload();
      persistor.pause();
      this.props.enableShortcuts();
    });
  };

  // Timer functions

  startTimer = () => {
    if (!this.state.timerRunning) {
      this.setState({
        startTime: Date.now() - this.props.sessionMs,
        timerRunning: true,
      });
    }
  };

  pauseTimer = () => {
    if (this.state.timerRunning) {
      const diff = Date.now() - this.state.startTime;
      this.props.setSessionTime(diff);
      this.setState({ timerRunning: false });
    }
  };

  handleVisibility = (isVisible) => {
    if (isVisible) {
      this.startTimer();
    } else {
      this.pauseTimer();
    }
  };

  handleClick = () => {
    postMessageToParent("interaction::phonic_click");
  }

  showHelp = () =>
    this.setState({ helpPopupOpen: true }, this.props.disableShortcuts);
  hideHelp = () =>
    this.setState({ helpPopupOpen: false }, this.props.enableShortcuts);

  render() {
    if (this.props.surveyError === "INVALID_SURVEY") {
      return <NotFound />;
    }

    if (!this.props.survey && !this.props.surveyError) return <Loading />;

    var display;
    switch (this.props.step) {
      case steps.WELCOME:
        if (this.props.survey.removeWelcomeScreen) {
          this.props.nextStep();
        }
        display = (
          <Welcome
            img={this.props.survey ? this.props.survey.welcomeImageUrl : null}
            clickToBegin={this.clickToBegin}
            survey={this.props.survey}
          />
        );
        break;
      case steps.WARNING:
        if (!showScreenRecordingWarning(this.props.survey)) {
          this.props.nextStep();
        }
        display = (
          <Warning
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
          />
        );
        break;
      case steps.SUMMARY:
        if (!this.props.survey.summary) {
          this.props.nextStep();
        }
        display = (
          <Summary
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
            survey={this.props.survey}
          />
        );
        break;
      case steps.CONSENT:
        if (!this.props.survey.consentPDFUri) {
          this.props.nextStep();
        }
        display = (
          <Consent
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
            survey={this.props.survey}
          />
        );
        break;
      case steps.MIC_CHECK:
        if (!this.props.survey.includeMicCheck) {
          this.props.nextStep();
        }
        display = (
          <MicCheck
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
            audioRecordingFormat={this.props.audioRecordingFormat}
            theme={this.props.survey.background}
            survey={this.props.survey}
          />
        );
        break;
      case steps.PERSONAL_INFO:
        if (!showPersonalInfo(this.props.survey)) {
          this.props.nextStep();
        }
        display = <PersonalInfo align={this.props.survey.align} />;
        break;
      case steps.QUESTION:
        if (this.props.survey.questions.length === 0) {
          this.props.nextStep();
        } else {
          display = <QuestionView />;
        }
        break;
      case steps.DEMOGRAPHICS:
        if (!showDemographics(this.props.survey)) {
          this.pauseTimer();
          this.props.nextStep();
        }
        display = <Demographics pauseTimer={() => this.pauseTimer()} />;
        break;
      case steps.FINISH:
        this.pauseTimer();
        display = (
          <Finished
            prevStep={this.props.prevStep}
            continuePopupOpen={this.state.continuePopupOpen}
          />
        );
        break;
      case steps.SCREEN_OUT:
        this.pauseTimer();
        display = <ScreenOut />;
        break;
      case steps.CLOSED:
                display = <Closed />;
        break;
      default:
                break;
    }
    return (
      <>
        <Helmet>
          <title>
            {this.props.survey && this.props.survey.name
              ? this.props.survey.name
              : "PHONIC"}
          </title>
        </Helmet>
        <ThemeProvider
          theme={getTheme(this.props.survey && this.props.survey.font)}
        >
          <div
            className={clsx(
              "App",
              this.getThemeClass(),
              this.getFontClass(),
              this.getAlignment()
            )}
            style={this.getBackgroundStyle()}
          >
            {this.props.survey && (
              <>
                <Helmet
                  htmlAttributes={{
                    lang: this.props.language
                      ? convertLangCodeToISO639(this.props.language)
                      : "en",
                  }}
                />
                {/*  Google Analytics */}
                {this.props.survey.googleTrackingID && (
                  <Helmet>
                    <script
                      async
                      src={`https://www.googletagmanager.com/gtag/js?id=${this.props.survey.googleTrackingID}`}
                    ></script>
                    <script>
                      {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', "${this.props.survey.googleTrackingID}");`}
                    </script>
                  </Helmet>
                )}

                {/* Facebook Pixel */}
                {this.props.survey.facebookPixelID && (
                  <Helmet>
                    <script>
                      {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${this.props.survey.facebookPixelID}');
              fbq('track', 'PageView');`}
                    </script>
                    <noscript>
                      {`
                <img
                  height="1"
                  width="1"
                  style="display:none"
                  src="https://www.facebook.com/tr?id=${this.props.survey.facebookPixelID}&ev=PageView&noscript=1"
                />`}
                    </noscript>
                  </Helmet>
                )}

                {/* Custom Header */}
                {this.props.survey.htmlHead && (
                  <>
                    {parseScriptsFromString(this.props.survey.htmlHead).map(
                      (s, idx) => {
                        return (
                          <Helmet
                            key={`HELMET_SCRIPT_${idx}`}
                            script={[
                              // Scripts can either have inner HTML or a src.
                              s.src
                                ? {
                                    type: "text/javascript",
                                    src: s.src,
                                    async: false,
                                  }
                                : {
                                    type: "text/javascript",
                                    innerHTML: s.text,
                                    async: false,
                                  },
                            ]}
                          />
                        );
                      }
                    )}
                  </>
                )}
              </>
            )}
            <div
              className={clsx(
                this.props.survey && this.props.survey.align,
                "content"
              )}
            >
              <PageVisibility onChange={this.handleVisibility} />
              {(this.props.conditions && this.props.conditions.truexSessionId)
                ? <div className="top-spacer" />
                : null
              }
              <Header
                survey={this.props.survey}
                preview={this.props.preview}
                img={
                  this.props.survey ? this.props.survey.topLeftImageUrl : null
                }
                theme={this.getHeaderFontColor()}
                progressValue={this.getProgressPercent()}
                isFinish={this.props.step === steps.FINISH || this.props.step === steps.SCREEN_OUT}
              />
              <div className="main">
                <GlobalToast />
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={8}
                  container
                  justifyContent="center"
                >
                  <div className="w-full">
                    {display}
                    {this.props.step === steps.WELCOME ||
                    (this.props.survey && this.props.survey.hideSupportText) ? (
                      <div className={this.props.conditions && this.props.conditions.truexSessionId
                        ? "" : "bottom-spacer"
                      } />
                    ) : (
                      <HelpText onClick={this.showHelp} />
                    )}
                  </div>
                </Grid>
              </div>
            </div>
            <HelpPopup
              open={this.state.helpPopupOpen}
              handleClose={this.hideHelp}
              submitFeedback={this.props.submitFeedback}
              feedback={this.props.feedback}
            />
            <ContinuePopup
              continue={this.handleContinuePopupClose}
              restart={this.restartSurvey}
              onClose={this.handleContinuePopupClose}
              open={this.state.continuePopupOpen}
            />
          </div>
        </ThemeProvider>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
