import React, { Component } from "react";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import { Grid, Typography } from "@material-ui/core";
import { strpLocalize } from "../localization/localize";

class Consent extends Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={8} md={8}>
          <Typography variant="h2" component="h1" className="title">
            {strpLocalize("Consent For Participation")}
          </Typography>
        </Grid>
        <Grid item xs={12} className="consent-iframe-container">
          <iframe
            title="Consent"
            type="application/pdf"
            src={this.props.survey.consentPDFUri}
          ></iframe>
        </Grid>
        <Grid item xs={12}>
          <PrevButton
            className="on-main-button-left"
            onClick={this.props.prevStep}
          />
          <MainButton onClick={this.props.nextStep}>
            <span>{strpLocalize("I Consent")}</span>
          </MainButton>
        </Grid>
      </Grid>
    );
  }
}

export default Consent;
