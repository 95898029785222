import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid, Box } from "@material-ui/core";
import AudioType from "./AudioType";
import Video from "./Video";
import Screen from "./Screen";
import Text from "./Text";
import Number from "./Number";
import Date from "./Date";
import SliderType from "./SliderType";
import File from "./File";
import CheckboxSelection from "./CheckboxSelection";
import RadioSelection from "./RadioSelection";
import Dropdown from "./Dropdown";
import Likert from "./Likert/index";
import Ranking from "./Ranking";
import { connect } from "react-redux";
import Code from "./Code";
import Stimuli from "../../components/Stimuli";
import {
  enableKeyboardSelection,
  disableKeyboardSelection,
} from "../../redux/actions";
import {
  strpLocalize,
  replaceStrOrLocalize,
} from "../../localization/localize";
import { separateParagraphs } from "../../utils/survey";
import { insertPipedText } from "../../utils/pipedText";

const mapStateToProps = (state) => {
  return {
    survey: state.survey,
    preview: state.preview,
    staging: state.survey.state === "DRAFT",
    conditions: state.conditions,
    questionIdx: state.questionIdx,
    question: state.question,
    questionState: state.questionState[state.questionIdx],
    fullQuestionState: state.questionState,
    language: state.language,
    videoRecordingQuality: state.videoRecordingQuality,
    pageQuery: state.conditions.page,
    disableShortcuts: state.disableShortcuts,
    disableKeySelection: state.disableKeySelection,
  };
};

const mapDispatchToProps = {
  enableKeyboardSelection,
  disableKeyboardSelection,
};

// This wrapper adds backup text functionality to Audio and Video questions
class BackupTextWrapper extends Component {
  render() {
    const type = this.props.question.type.split("_")[0]; // AUDIO_SLIDER => AUDIO
    return (
      <>
        {this.props.mode !== "BACKUP_TEXT" && type === "AUDIO" && (
          <AudioType {...this.props} />
        )}
        {this.props.mode !== "BACKUP_TEXT" && type === "VIDEO" && (
          <Video {...this.props} />
        )}
        {this.props.mode === "BACKUP_TEXT" && (
          <Text
            question={this.props.question}
            onSubmit={this.props.dataChange.bind(this, "BACKUP_TEXT")}
            data={this.props.data}
            pageQuery={this.props.pageQuery}
            enableKeyboardSelection={this.props.enableKeyboardSelection}
            disableKeyboardSelection={this.props.disableKeyboardSelection}
          />
        )}
        {this.props.question.allowBackupText && (
          <button
            className="audio-toggle-mode subtitle"
            onClick={() => {
              this.props.handleModeChange(
                this.props.mode !== "BACKUP_TEXT" ? "BACKUP_TEXT" : type
              );
            }}
          >
            {this.props.mode === "BACKUP_TEXT"
              ? strpLocalize(`Respond with ${type.toLowerCase()}`)
              : strpLocalize("Or click here to answer with text")}
          </button>
        )}
      </>
    );
  }
}

class QuestionWrapper extends Component {
  dataChange = (type, data, options ={}) => {
    this.props.onSubmit(type, data, options);
  };

  getSelectionView() {
    if (!this.props.question) return null;

    if (this.props.question.exclusive) {
      return (
            <RadioSelection
              key={`RADIO-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "SELECTION")}
              data={this.props.questionState.data["SELECTION"]}
              disableKeySelection={this.props.disableKeySelection}
              disableShortcuts={this.props.disableShortcuts}
              survey={this.props.survey}
              questionsState={this.props.fullQuestionState}
              preview={this.props.preview}
              isTruex={this.props.conditions && this.props.conditions.truexSessionId}
            />
      );
    } else {
      return (
            <CheckboxSelection
              key={`CHECKBOX-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "SELECTION")}
              data={this.props.questionState.data["SELECTION"]}
              disableKeySelection={this.props.disableKeySelection}
              disableShortcuts={this.props.disableShortcuts}
              survey={this.props.survey}
              questionsState={this.props.fullQuestionState}
              preview={this.props.preview}
              isTruex={this.props.conditions && this.props.conditions.truexSessionId}
            />
      );
    }
  }

  getTranslatedTitle(key /* either text or subText */) {
    const shouldInsertPipedText = !(
      this.props.preview === true && this.props.staging === true
    );
    if (!this.props.question.translations) {
      let title = this.props.question[key];
      title = strpLocalize(title);
      if (shouldInsertPipedText) {
        title = insertPipedText(
          title,
          this.props.survey,
          this.props.fullQuestionState,
          this.props.conditions
        );
      }
      return separateParagraphs(title);
    }

    let translation = replaceStrOrLocalize(
      this.props.question[key],
      this.props.question.translations[key],
      this.props.language
    );
    if (shouldInsertPipedText) {
      translation = insertPipedText(
        translation,
        this.props.survey,
        this.props.fullQuestionState,
        this.props.conditions
      );
    }
    return separateParagraphs(translation);
  }

  getTitle = () => {
    if (!this.props.question) return null;
    return this.props.question.text ? this.getTranslatedTitle("text") : "";
  };

  getSubTitle = () => {
    if (!this.props.question) return null;
    if (this.props.question.subText) return this.getTranslatedTitle("subText");

    switch (this.props.question.type) {
      case "DISPLAY":
        return (
          <span style={{ marginBottom: 12 }}>
            {strpLocalize(this.props.question.subText)}
          </span>
        );

      case "AUDIO":
        return strpLocalize("Audio Response");

      case "VIDEO":
        return strpLocalize("Video Response");

      case "SCREEN":
        return strpLocalize("Screen Recording");

      case "TEXT":
        return strpLocalize("Text Response");

      case "NUMBER":
        return strpLocalize("Enter a Number");
      case "DATE":
        return strpLocalize("Enter a Date");
      case "SLIDER":
        return strpLocalize("Drag the Slider");
      case "DROPDOWN":
        return strpLocalize("Select One");
      case "SELECTION":
        if (this.props.question.exclusive) return strpLocalize("Select One");
        return strpLocalize("Check All That Apply");

      case "AUDIO_SELECTION":
        if (this.props.question.exclusive)
          return strpLocalize(
            "Select one and use the microphone to explain your choice."
          );
        return strpLocalize(
          "Check all that apply and use the microphone to explain your choice."
        );

      case "AUDIO_TEXT":
        return strpLocalize(
          "Type a response and use the microphone to explain your choice."
        );

      case "AUDIO_SLIDER":
        return strpLocalize(
          "Drag the slider and use the microphone to explain your choice."
        );

      case "THINKALOUD":
        return strpLocalize(
          "The microphone is already recording. Speak your thoughts freely."
        );

      case "FILE":
        return strpLocalize("File Upload");

      case "LIKERT":
        return strpLocalize("Rate the following items.");

      case "RANKING":
        return strpLocalize("Rank the following items.");

      case "AUDIO_RANKING":
        return strpLocalize(
          "Rank the following items and use the microphone to explain your choice."
        );

      default:
        return;
    }
  };

  getInput = () => {
    if (!this.props.question) return null;

    switch (this.props.question.type) {
      case "DISPLAY":
        return null;
      case "AUDIO":
        return (
          <BackupTextWrapper
            key={`AUDIO-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "AUDIO")}
            questionIdx={this.props.questionIdx}
            toggleAudioRecording={this.props.toggleAudioRecording}
            audioParams={this.props.audioParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["BACKUP_TEXT"]}
            file={this.props.questionState.data["AUDIO"]}
            theme={this.props.survey.background}
            align={this.props.survey.align}
            mode={this.props.questionState.mode}
            handleModeChange={this.props.handleModeChange}
          />
        );
      case "VIDEO":
        return (
          <BackupTextWrapper
            key={`VIDEO-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "VIDEO")}
            questionIdx={this.props.questionIdx}
            videoParams={this.props.videoParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["BACKUP_TEXT"]}
            align={this.props.survey.align}
            mode={this.props.questionState.mode}
            handleModeChange={this.props.handleModeChange}
            videoRecordingQuality={this.props.videoRecordingQuality}
          />
        );
      case "SCREEN":
        return (
          <Screen
            key={`SCREEN-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "SCREEN")}
            questionIdx={this.props.questionIdx}
            videoParams={this.props.videoParams}
            dataChange={this.dataChange.bind(this)}
            data={this.props.questionState.data["SCREEN"]}
            align={this.props.survey.align}
            camera={false}
          />
        );
      case "TEXT":
        return (
          <Text
            key={`TEXT-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "TEXT")}
            data={this.props.questionState.data["TEXT"]}
            pageQuery={this.props.pageQuery}
          />
        );
      case "NUMBER":
        return (
          <Number
            key={`NUMBER-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "NUMBER")}
            data={this.props.questionState.data["NUMBER"]}
            align={this.props.survey.align}
            pageQuery={this.props.pageQuery}
          />
        );
      case "DATE":
        return (
          <Date
            key={`DATE-${this.props.questionIdx}`}
            onSubmit={this.dataChange.bind(this, "DATE")}
            data={this.props.questionState.data["DATE"]}
            align={this.props.survey.align}
            pageQuery={this.props.pageQuery}
          />
        );
      case "SLIDER":
        return (
          <SliderType
            key={`SLIDER-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "SLIDER")}
            data={this.props.questionState.data["SLIDER"]}
          />
        );
      case "FILE":
        return (
          <File
            key={`FILE-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "FILE")}
            data={this.props.questionState.data["FILE"]}
          />
        );
      case "DROPDOWN":
        return (
          <Dropdown
            key={`DROPDOWN-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "DROPDOWN")}
            data={this.props.questionState.data["DROPDOWN"]}
            survey={this.props.survey}
            questionsState={this.props.fullQuestionState}
            preview={this.props.preview}
          />
        );
      case "SELECTION":
        return this.getSelectionView();
      case "LIKERT":
        return (
          <Likert
            key={`LIKERT-${this.props.questionIdx}`}
            question={this.props.question}
            survey={this.props.survey}
            questionsState={this.props.fullQuestionState}
            preview={this.props.preview}
            onSubmit={this.dataChange.bind(this, "LIKERT")}
            data={this.props.questionState.data["LIKERT"]}
          />
        );
      case "RANKING":
        return (
          <Ranking
            key={`RANKING-${this.props.questionIdx}`}
            question={this.props.question}
            onSubmit={this.dataChange.bind(this, "RANKING")}
            data={this.props.questionState.data["RANKING"]}
          />
        );
      case "AUDIO_SELECTION":
        return (
          <div>
            {this.props.question.recorderPosition === "bottom" &&
              this.getSelectionView()}
            <BackupTextWrapper
              key={`AUDIO_SELECTION-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
              enableKeyboardSelection={this.props.enableKeyboardSelection}
              disableKeyboardSelection={this.props.disableKeyboardSelection}
            />
            {this.props.question.recorderPosition !== "bottom" &&
              this.getSelectionView()}
          </div>
        );
      case "AUDIO_TEXT":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
            />
            <Text
              key={`TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              data={this.props.questionState.data["TEXT"]}
            />
          </div>
        );
      case "AUDIO_SLIDER":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_SLIDER-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
            />
            <SliderType
              key={`SLIDER-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "SLIDER")}
              data={this.props.questionState.data["SLIDER"]}
            />
          </div>
        );
      case "THINKALOUD":
        return (
          <div>
            <BackupTextWrapper
              key={`THINKALOUD-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
            />
            <Text
              key={`TEXT-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              disabled={this.props.audioParams.isRecording === false}
              data={this.props.questionState.data["TEXT"]}
            />
          </div>
        );
      case "THINKALOUD_CODE":
        return (
          <div>
            <BackupTextWrapper
              key={`THINKALOUD_CODE-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              align={this.props.survey.align}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
            />
            <Code
              key={`CODE-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "TEXT")}
              disabled={this.props.audioParams.isRecording === false}
            />
          </div>
        );
      case "AUDIO_RANKING":
        return (
          <div>
            <BackupTextWrapper
              key={`AUDIO_RANKING-${this.props.questionIdx}`}
              thinkAloud={true}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "AUDIO")}
              questionIdx={this.props.questionIdx}
              toggleAudioRecording={this.props.toggleAudioRecording}
              audioParams={this.props.audioParams}
              dataChange={this.dataChange.bind(this)}
              data={this.props.questionState.data["BACKUP_TEXT"]}
              file={this.props.questionState.data["AUDIO"]}
              theme={this.props.survey.background}
              mode={this.props.questionState.mode}
              handleModeChange={this.props.handleModeChange}
            />
            <Ranking
              key={`RANKING-${this.props.questionIdx}`}
              question={this.props.question}
              onSubmit={this.dataChange.bind(this, "RANKING")}
              data={this.props.questionState.data["RANKING"]}
            />
          </div>
        );
      default:
        return (
          <div style={{ margin: 30 }}>
            {strpLocalize("Unsupported Question Type")}
          </div>
        );
    }
  };

  render() {
    return (
      <>
        <Typography variant="h2" className="title" gutterBottom>
          {this.getTitle()}
        </Typography>
        <Typography variant="h3" className="subtitle" gutterBottom>
          {this.getSubTitle()}
        </Typography>
        <div className="pt-1">
          {this.props.question.stimuli && this.props.question.stimuli.type && (
            <Stimuli
                key={`STIMULI-${this.props.questionIdx}`}
                stimuli={this.props.question.stimuli}
            />
          )}
          {this.props.question && this.props.question.type !== "LIKERT"
            ? (<Grid alignItems="center" justifyContent="center" container>
              <Grid item xs={12} sm={11} md={9}>
                {this.getInput()}
              </Grid>
            </Grid>)
            : this.getInput()
          }
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionWrapper);
