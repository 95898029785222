import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.backgroundColor,
    color: "#333333",
    height: 48,
    padding: "12px 24px",
    border: "1px solid #333333",
    margin: "10px 0",
    fontSize: 20,
    minWidth: 240,
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 160,
      borderRadius: 8,
      height: 40,
      padding: "6px 12px",
      fontSize: 14,
    },
  },
}));

const GeneralButton = ({ className, children, backgroundColor, ...other }) => {
  const classes = useStyles({ backgroundColor });

  return (
    <Button
      className={clsx(classes.root, className)}
      {...other}
      variant="contained"
    >
      {children}
    </Button>
  );
};

GeneralButton.defaultProps = {
  backgroundColor: "#f0f0f0",
};

export default GeneralButton;
