import React, { createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import KeyboardButton from "./KeyboardButton";
import { strpLocalize } from "../../localization/localize";
import KeyboardKey from "../Keyboard/KeyboardKey";
import KeyboardNavigation from "../Keyboard/KeyboardNavigation";
import KeyboardShortcutInfo, { ENTER_KEY } from "../Keyboard/KeyboardShortcutInfo";

const useStyles = (theme) => ({
  root: {
    color: "white",
    height: 48,
    minWidth: 145,
    fontSize: 20,
    padding: "12px 20px",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 8,
      height: 40,
      fontSize: 16,
      padding: "6px 10px",
      minWidth: 125,
    },
  },
});

const ShortcutTip = ({ shiftPress, cmdPress, enterPress }) => {
  return (
    <KeyboardShortcutInfo action="continue">
      <KeyboardKey pressed={enterPress && !shiftPress && !cmdPress}>
        {ENTER_KEY}
      </KeyboardKey>
    </KeyboardShortcutInfo>
  );
};
class GeneralButton extends React.Component {
  state = {
    cmdPress: false,
    isHovering: false,
    enterPress: false,
    shiftPress: false,
  };
  buttonRef = createRef(null);

  continueSurvey = () => {
    if (
      this.state.enterPress &&
      !this.state.shiftPress &&
      !this.state.cmdPress &&
      this.buttonRef.current &&
      this.buttonRef.current.keyboardClick
    ) {
      this.buttonRef.current.keyboardClick(null, () =>
        this.setState({ enterPress: false })
      );
    }
  };

  render() {
    const { classes, className, alwaysShowShortcut, ...other } = this.props;
    return (
      <>
        <KeyboardButton
          ref={this.buttonRef}
          className={clsx(classes.root, className, "main-button")}
          {...other}
          tooltipProps={{
            title: (
              <ShortcutTip
                shiftPress={this.state.shiftPress}
                cmdPress={this.state.cmdPress}
                enterPress={this.state.enterPress}
              />
            ),
            placement: "bottom",
            open: this.state.isHovering || alwaysShowShortcut,
          }}
          tooltipAriaLabel={strpLocalize("Next")}
          onMouseOver={() => this.setState({ isHovering: true })}
          onMouseLeave={() => this.setState({ isHovering: false })}
        >
          {this.props.children}
        </KeyboardButton>
        <KeyboardNavigation
          cmdDown={(value) => this.setState({ cmdPress: value })}
          shiftDown={(value) => this.setState({ shiftPress: value })}
          enterDown={(value) => {
            this.setState({ enterPress: value }, this.continueSurvey);
          }}
        />
      </>
    );
  }
}

export default withStyles(useStyles)(GeneralButton);
