import React, { Component } from "react";

import { Grid, Box } from "@material-ui/core";
import Header from "../components/Header/Header";
import HelpContent from "../components/Help/HelpContent";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

class Help extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Phonic Survey Help</title>
        </Helmet>
        <div className="Help">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Header />
            </Grid>
            <Grid item xs={10} sm={8}>
              <h1>Phonic Survey Help</h1>
              <br />
              <HelpContent includeContact={true} />
            </Grid>
          </Grid>
          <Box height={100} />
        </div>
      </>
    );
  }
}

export default withRouter(Help);
