import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { skipToQuestionIdx } from "../redux/actions";
import { showPreviousButton, separateParagraphs } from "../utils/survey";
import {
  Checkbox,
  FormControlLabel,
  Popover,
  Typography,
} from "@material-ui/core";
import MainButton from "../components/Buttons/MainButton";
import PrevButton from "../components/Buttons/PrevButton";
import DisconnectedView from "../components/Recording/DisconnectedView";
import { strpLocalize } from "../localization/localize";

const mapStateToProps = (state) => ({
  preview: state.preview,
  question: state.question,
  questionState: state.questionState[state.questionIdx],
  survey: state.survey,
  questionIdx: state.questionIdx,
});

const mapDispatchToProps = { skipToQuestionIdx };

const MissionStart = (props) => {
  const [anchorHelp, setAnchorHelp] = React.useState(null);
  const mission = props.survey.questionGroups[props.question.groupId];

  const showHelp = (event) => {
    setAnchorHelp(event.currentTarget);
  };

  const hideHelp = () => {
    setAnchorHelp(null);
  };

  const findMissionStartQuestionIdx = () => {
    for (let idx = 0; idx < props.survey.questions.length; idx++) {
      const question = props.survey.questions[idx];
      if (question.groupId === props.question.groupId)
        return Math.max(0, idx - 1);
    }
  };

  const goToFirstMissionQ = () =>
    props.skipToQuestionIdx(findMissionStartQuestionIdx());

  const formatMessage = (message) => separateParagraphs(strpLocalize(message));

  const marginLeft = props.survey.align === "left" ? 0 : "auto";
  const marginRight = props.survey.align === "right" ? 0 : "auto";

  return (
    <div className={props.survey.align}>
      {mission && (
        <>
          <Typography variant="h2" className="title" gutterBottom>
            {formatMessage(mission.title)}
          </Typography>
          <div
            className="screen-wrapper rounded"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              margin: `2em ${marginRight} 2em ${marginLeft}`,
            }}
          >
            <DisconnectedView
              message={
                <div
                  className="flex items-center message-wrapper"
                  style={{ height: "100%" }}
                >
                  You are about to start a Mission. Your browser may request
                  access to your screen, camera and microphone
                </div>
              }
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                color="info"
                className="previous-button"
              />
            }
            label={
              <span className="previous-button">
                Mission tutorial
                <FontAwesomeIcon
                  size="sm"
                  icon={faQuestionCircle}
                  className="question-circle"
                  onMouseEnter={showHelp}
                  onMouseLeave={hideHelp}
                  style={{ marginLeft: "0.2em" }}
                />
              </span>
            }
            onChange={(event) => {
              props.setMissionTour(event.target.checked);
            }}
          />
          <Popover
            id="help-popover"
            style={{
              pointerEvents: "none",
            }}
            open={Boolean(anchorHelp)}
            anchorEl={anchorHelp}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            onClose={hideHelp}
            disableRestoreFocus
          >
            <div style={{ width: "15em", padding: "0.5em" }}>
              <Typography>
                We recommend tutorial for first time respondents
              </Typography>
            </div>
          </Popover>
          <div className="button-bar">
            {showPreviousButton(props.survey, props.questionIdx) && (
              <PrevButton onClick={goToFirstMissionQ}>
                {props.survey.buttonType === "chevron" && (
                  <>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faChevronLeft}
                      className="chevron-left"
                    />
                  </>
                )}
              </PrevButton>
            )}
            <MainButton
              className="next-button"
              onClick={props.onStart}
              startIcon={props.survey.buttonType === "chevron"}
            >
              Start Mission
            </MainButton>
          </div>
        </>
      )}
      {props.question.footer && (
        <div className="footer">
          <div
            dangerouslySetInnerHTML={{
              __html: strpLocalize(props.question.footer),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionStart);
